<template>
  <div class="publish-container">
    <!-- <el-alert
      v-if="tenderDetail && tenderDetail.apprvStatus === 'F'"
      :title="tenderDetail.apprvRemark"
      type="error"
      show-icon
    >
    </el-alert> -->
    <div class="form-content">
      <!-- {{ RulesShow }} -->
      <!-- {{ publishForm }} \\\\\ -->
      <!-- bidId  \\\\\{{ bidId }}
      isbidId
        {{ isbidId}}
        lastid{{lastid}} -->
      <!-- {{ this.publishForm.arbAdditionInfo }} -->
      <div class="transparentBox" v-if="examineShow"></div>
      <el-form
        inline
        :model="publishForm"
        :rules="publishFormRules"
        ref="publishForm"
        label-width="130px"
      >
        <div class="basicBox">
          <div class="titleFont">基本信息</div>
          <div class="basic">
            <el-form-item
              class="form-item"
              label="订单模式："
              prop="orderProcess"
            >
              <!-- <el-radio-group v-model="publishForm.orderProcess">
            <el-radio label="HK">先货后款</el-radio>
            <el-radio label="KH">先款后货</el-radio>
          </el-radio-group> -->
              <el-select
                class="select-style"
                v-model="publishForm.orderProcess"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in paymentmethod.orderProcess"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="form-item" label="标的名称：" prop="bidName">
              <el-input
                class="input-width"
                v-model="publishForm.bidName"
                maxlength="50"
                placeholder="请输入标的名称"
                size="small"
              ></el-input>
            </el-form-item>
            <!-- {{ this.publishForm.categoryId }} -->
            <el-form-item
              class="form-item"
              label="产品类型："
              prop="categoryId"
            >
              <el-cascader
                class="input-width"
                v-model="publishForm.categoryId"
                :options="categoryList"
                size="small"
              ></el-cascader>
            </el-form-item>

            <el-form-item label="标的有效期：" prop="dateTime">
              <el-date-picker
                class="input-width"
                size="small"
                v-model="publishForm.dateTime"
                @change="onValidityDateTimeChange"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
                :picker-options="pickerStartOptions"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="this.nowTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-row>
              <el-form-item
                class="form-item"
                label="合同模板："
                prop="contractInfo"
              >
                <el-select
                  class="input-width"
                  v-model="publishForm.contractInfo"
                  placeholder="请选择合同模板"
                  :popper-append-to-body="false"
                >
                  <el-option
                    id="contract-id"
                    v-for="item in contractList"
                    :key="item.tContractTextNo"
                    :label="item.tContractTextName"
                    :value="item.tContractTextNo"
                  >
                    <div class="contract-box">
                      <div class="contract-left">
                        <span class="name">{{ item.tContractTextName }}</span>
                        <span class="num">{{ item.tContractTextNo }}</span>
                      </div>
                      <div
                        class="contract-rigth"
                        @click="showContractDialog(item.id)"
                        style="color: #8492a6"
                      >
                        查看
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="form-item"
                label="合同编号："
                prop="contractNumber"
              >
                <el-input
                  class="input-width"
                  v-model="publishForm.contractNumber"
                  maxlength="50"
                  placeholder="请输入合同编号"
                  size="small"
                  onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                  @input.native="valueNumber($event, 'contractNumber')"
                ></el-input>
              </el-form-item>
            </el-row>
          </div>
        </div>

        <div class="basicBox">
          <div class="titleFont">联系信息</div>
          <div class="basic">
            <el-form-item class="form-item" label="联系人：" prop="contacts">
              <el-input
                class="input-width"
                v-model="publishForm.contacts"
                placeholder="请输入联系人"
                size="small"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item" label="手机号码：" prop="phone">
              <el-input
                class="input-width"
                v-model="publishForm.phone"
                placeholder="请输入联系电话"
                maxlength="11"
                size="small"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
            <el-row>
              <el-form-item
                class="form-item"
                label="收货地址："
                prop="deliveryAddress"
              >
                <el-input
                  class="input-width"
                  v-model="publishForm.deliveryAddress"
                  placeholder="请输入收货地址"
                  size="small"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-row>
          </div>
        </div>
        <div class="basicBox">
          <div class="titleFont">发票信息</div>
          <div class="basic">
            <el-row>
              <el-form-item
                class="form-item"
                label="发票类型："
                prop="invoiceType"
              >
                <el-radio-group v-model="publishForm.invoiceType">
                  <el-radio label="0">专用发票</el-radio>
                  <el-radio disabled label="1">普通发票</el-radio>
                  <!--                <el-radio label="1">区块链发票</el-radio>
                                <el-radio label="1">通用机打电子发票</el-radio>-->
                </el-radio-group>
              </el-form-item>

              <el-form-item
                class="form-item"
                label="适用税率(%)："
                prop="arbAdditionInfo.taxRate"
              >
                <el-input
                  class="input-width"
                  v-model="publishForm.arbAdditionInfo.taxRate"
                  placeholder="请输入税率"
                  size="mini"
                  @input.native="valueChange($event, 'taxRate')"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <!-- {{ publishForm.arbAdditionInfo.invsStatus }} -->
              <el-form-item
                class="form-item"
                label="开票时间："
                prop="arbAdditionInfo.invsStatus"
              >
                <el-radio-group
                  v-model="publishForm.arbAdditionInfo.invsStatus"
                >
                  <el-radio label="KQ">付款前开发票</el-radio>
                  <el-radio label="KH">付款后开发票</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                class="form-item-invsStatus"
                prop="arbAdditionInfo.invsDay"
              >
                <div
                  style="font-size: 14px"
                  :style="{
                    color:
                      publishForm.arbAdditionInfo.invsStatus == 'KH'
                        ? '#000'
                        : '#c0c4cc',
                  }"
                >
                  付款之日起【<el-input
                    class="input-width-small"
                    :disabled="
                      this.publishForm.arbAdditionInfo.invsStatus == 'KH'
                        ? false
                        : true
                    "
                    v-model="publishForm.arbAdditionInfo.invsDay"
                    size="mini"
                  ></el-input
                  >】个工作日内开票
                </div>
              </el-form-item>
            </el-row>
          </div>
        </div>

        <div class="btnBoxStyle">
          <!-- <el-button type="primary" @click="onPulish">{{
            $route.query.isEdit === "true" ? "修改" : "立即发布"
          }}</el-button> -->
          <el-button class="btnstyle" type="primary" @click="next"
            >下一步</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- 查看合同模板 -->
    <el-dialog
      title="合同模板"
      :visible.sync="contractDialogVisible"
      width="700px"
    >
      <div v-html="contractTemplate"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBIContract,
  categoryAll,
  addCompetitive,
  updateCompetitive,
  getSelectBiddingDetails,
  importExcel,
  getContractInfo,
  myAuthority,
} from "../../../../api";
import { formatNewDate } from "@/utils/util";
import tinymceEditor from "@/components/tinymceEditor.vue";
import Title from "../../../../component/Title";
// import step-nav from "../../../component/step-nav";
import stepNav from "../../../../component/step-nav.vue";

import { regRule } from "@/utils/validate";
import moment from "moment";

export default {
  components: {
    Title,
    tinymceEditor,
    stepNav,
  },

  props: {
    publishForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isbidId: {
      type: Number,
      default: () => {
        return {};
      },
    },
    lastid: {
      type: Number,
      default: () => {
        return {};
      },
    },
    examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // $route: function (to) {
    //   console.log(to);
    //   if (Object.keys(to.query).length == 0) {
    //     this.examineShow = false;
    //   }
    // },

    "publishForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.publishForm.arbAdditionInfo.invsStatus = "KH";
      }
    },

    "publishForm.arbAdditionInfo.paySet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.paySetPort = "";
      }
      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.paySetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverSet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "";
      }
      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.deliverSetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "SH" && oldVal == "ZT") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
      if (newVal == "ZT" && oldVal == "SH") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
    },
    "publishForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
      if (newVal == "GZB" && oldVal == "ORTHER") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
    },
    "publishForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);

      if (newVal == "KQ" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsDay = "";
      }
      if (newVal == "KH") {
        this.invsDayShow = false;
        console.log("11", this.invsDayShow);
      }
      if (newVal !== "KH") {
        this.invsDayShow = true;
        console.log("11", this.invsDayShow);
      }
    },
    // immediate: true,
    // },
    "publishForm.payMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);

      if (this.publishForm.payMode !== undefined) {
        if (
          // this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
          // this.publishForm.payMode.indexOf("CDHP") == -1
          this.publishForm.payMode.length == 1 &&
          this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
          this.publishForm.payMode !== ""
        ) {
          console.log("长度是1");
          this.publishForm.arbAdditionInfo.yhzzPercentage = "100";
          this.publishForm.arbAdditionInfo.cdhpPercentage = "";
        }
        if (
          this.publishForm.payMode.indexOf("CDHP") !== -1 &&
          this.publishForm.payMode.length == 1 &&
          this.publishForm.payMode !== ""
        ) {
          this.publishForm.arbAdditionInfo.cdhpPercentage = "100";
          this.publishForm.arbAdditionInfo.yhzzPercentage = "";
        }
      }
    },
  },
  data() {
    let validateUnitPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入单价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("请输入正确的报价"));
      } else if (value >= 10000000000) {
        return callback(new Error("价格调整幅度需小于 10000000000 元"));
      } else {
        callback();
      }
    };
    let validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("小数位数不允许大于2位")); //小数点后两位
      } else if (value.indexOf(".") != -1 && value.split(".")[0] == "") {
        callback(new Error("请输入正确的数字")); //小数点后两位
      } else if (value.indexOf(".") != -1 && value.split(".")[1] == "") {
        callback(new Error("请输入正确的数字")); //小数点后无数字
      } else {
        callback();
      }
    };
    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var validateStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择有效期"));
      } else if (formatNewDate(value[0]).getTime() < Date.now()) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else if (
        formatNewDate(value[1]).getTime() - formatNewDate(value[0]).getTime() >
        1000 * 60 * 60 * 24 * 30
      ) {
        return callback(new Error("有效期时间不能大于 30 天"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else if (value.indexOf(".") !== -1) {
        return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
      } else if (value.length !== 1 && value[0] == 0) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else {
        callback();
      }
    };
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入采购量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 6
      ) {
        return callback(new Error("最多保留六位小数"));
      } else {
        callback();
      }
    };

    var validatePayAfterData = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.invsStatus == "KH") {
        console.log("value", value);
        console.log("value", typeof value);

        if (!value || isNaN(value) || value <= 0 || value > 180) {
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") != -1) {
          callback(new Error("请输入1 ~ 180之间的正整数")); //防止输入多个小数点
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateInvsDay = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.invsStatus == "KH") {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          this.publishForm.arbAdditionInfo.invsDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") != -1) {
          this.publishForm.arbAdditionInfo.invsDay = "";
          callback(new Error("请输入1 ~ 180之间的正整数")); //防止输入多个小数点
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.publishForm.payMode.indexOf("CDHP"));
      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validatefqTermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };
    //标的校验
    var validateBidName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入标的名称"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    //合同编号的校验
    var validateContractNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入合同编号"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    //联系人校验
    var validateContacts = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入联系人"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    var validateDeliveryAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入收货信息"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    return {
      stepManage: {
        data: ["1.标的信息", "2.添加产品", "3.付款信息", "4.发货信息"],
        stepNum: 0,
      },
      deliverSetList: false,
      paySetList: false,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      pickerStartOptions: {
        disabledDate: (time) => {
          return (
            time.getTime() < Date.now() - 1000 * 60 * 60 * 24 ||
            time.getTime() - Date.now() > 1000 * 60 * 60 * 24 * 31
          );
        },
      },
      deliveryPickerStartOptions: {
        disabledDate: (time) => {
          if (this.publishForm.dateTime) {
            let date = formatNewDate(this.publishForm.dateTime[1]);
            return (
              time.getTime() <
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).getTime()
            );
          } else {
            let date = new Date();
            return (
              time.getTime() <
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).getTime()
            );
          }
        },
      },
      nowTime: [],
      //数据
      // publishForm: {
      //   bidName: "",
      //   categoryId: "",
      //   bidPrice: 0,
      //   startTime: "",
      //   endTime: "",
      //   dateTime: "",
      //   contacts: "",
      //   phone: "",
      //   contractInfo: "",
      //   deliveryAddress: "",
      //   bidRequire: "",
      //   delRequire: "",
      //   pricRequire: "",
      //   qualRequire: "",
      //   taxRate: "",
      //   deliveryDate: "",
      //   orderProcess: "HK",
      //   proList: [],
      //   payMode: [],
      //   bankBillTerm: "",
      //   invoiceType: "",

      //   //付款分期表格
      //   payDeliverRateFkList: [],
      //   //交货分期表格
      //   payDeliverRateJhList: [],
      //   arbAdditionInfo: {
      //     fqTermIndex: "",
      //     type: "BI", //竞卖
      //     taxRate: "", // 使用税率
      //     invsStatus: "KQ", // 开票时间
      //     paySet: "", //付款安排
      //     deliverSet: "", //交付安排
      //     deliverMode: "", // 交货方式
      //     packageMode: "", //包装方式
      //     checkMode: "", //验收条件
      //     qualityMode: "", //质保方式
      //     qualityTerm: "", //质保的日期
      //     paySetPort: "", // 付款安排数据
      //     deliverSetPort: "", // 交付安排数据

      //     invsDay: "", //付款后开发票日期
      //     deliverModeDay: "", //买家自提的日期
      //     bankBillTerm: "", //承兑汇票期限
      //     payMode: "",
      //     //------------------------
      //     paySetDay: "", //付款安排一次性付款日期

      //     //---------------------------
      //     deliverSetDay: "", //交货安排一次性交货日期

      //     //-----------------------------
      //     payPercent: "", // 付款占比
      //     yhzzPercentage: "", //银行转账百分比
      //     cdhpPercentage: "", //承兑汇票百分比
      //   },
      // },
      invsDayShow: true,
      userAddressList: [],
      categoryList: [],
      noRules: {},
      publishFormRules: {
        bidName: [
          { required: true, validator: validateBidName, trigger: "blur" },
        ],

        contractNumber: [
          {
            required: true,
            validator: validateContractNumber,
            trigger: "blur",
          },
        ],
        contacts: [
          { required: true, validator: validateContacts, trigger: "blur" },
        ],
        taxRate: [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            pattern: regRule.regPhoneStrict,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
        ],
        // bidPrice: [
        //   {
        //     required: true,
        //     validator: validatePrice,
        //     msg: "请输入标的价格",
        //     trigger: "blur",
        //   },
        // ],
        dateTime: [
          {
            required: true,
            validator: validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        deliveryDate: [
          {
            required: true,
            message: "请选择交货时间",
            trigger: ["blur", "change"],
          },
        ],
        deliveryAddress: [
          {
            required: true,
            validator: validateDeliveryAddress,
            trigger: ["blur", "change"],
          },
        ],
        contractInfo: [
          {
            required: true,
            message: "请选择合同模板",
            trigger: ["blur", "change"],
          },
        ],
        orderProcess: [
          {
            required: true,
            message: "请选择订单模式",
            trigger: ["blur", "change"],
          },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validateInvsDay,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        // "arbAdditionInfo.acceptanceQualifiedDate": [
        //   {
        //     required: true,
        //     validator: validatePayAfterData,
        //     trigger: ["blur", "change"],
        //   },
        // ],
        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validatePayAfterData,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,
            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["blur", "change"],
          },
        ],
      },
      productManage: {
        form: {
          productName: "",
          productBrand: "",
          productType: "",
          specification: "",
          number: "",
          unit: "",
          unitPrice: "",
          totalPrice: "",
          require: "",
        },
        formRule: {
          productName: [
            { required: true, message: "请输入产品名称", trigger: "blur" },
          ],
          productBrand: [
            { required: true, message: "请输入产品品牌", trigger: "blur" },
          ],
          productType: [
            { required: true, message: "请输入产品型号", trigger: "blur" },
          ],
          specification: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          number: [
            { required: true, validator: validateNumber, trigger: "blur" },
          ],
          unit: [{ required: true, message: "请选择单位", trigger: "blur" }],
          unitPrice: [
            {
              required: true,
              validator: validateUnitPrice,
              msg: "请输入单价",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        editIndex: "",
        dialogVisible: false,
      },
      contractList: [],
      contractDialogVisible: false,
      contractTemplate: "",
      tenderDetail: null,
      importExcel,
      paymentmethod: {
        payMode: "",
        bankBillTerm: "",
        orderProcess: [
          {
            value: "HK",
            label: "先货后款",
          },
          {
            value: "KH",
            label: "先款后货",
          },
        ],
        payListOption: [
          {
            value: "1",
            label: "第1期",
          },
          {
            value: "2",
            label: "第2期",
          },
          {
            value: "3",
            label: "第3期",
          },
          {
            value: "4",
            label: "第4期",
          },
          {
            value: "5",
            label: "第5期",
          },
          {
            value: "6",
            label: "第6期",
          },
          {
            value: "7",
            label: "第7期",
          },
          {
            value: "8",
            label: "第8期",
          },
          {
            value: "9",
            label: "第9期",
          },
          {
            value: "10",
            label: "第10期",
          },
          {
            value: "11",
            label: "第11期",
          },
          {
            value: "12",
            label: "第12期",
          },
          {
            value: "13",
            label: "第13期",
          },
          {
            value: "14",
            label: "第14期",
          },
          {
            value: "15",
            label: "第15期",
          },
          {
            value: "16",
            label: "第16期",
          },
          {
            value: "17",
            label: "第17期",
          },
          {
            value: "18",
            label: "第18期",
          },
          {
            value: "19",
            label: "第19期",
          },
          {
            value: "20",
            label: "第20期",
          },
          {
            value: "21",
            label: "第21期",
          },
          {
            value: "22",
            label: "第22期",
          },
          {
            value: "23",
            label: "第23期",
          },
          {
            value: "24",
            label: "第24期",
          },
        ],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
    };
  },
  computed: {
    RulesShow: function () {
      if (this.examineShow == true) {
        return this.noRules;
      } else {
        return this.publishFormRules;
      }
    },
  },
  created() {
    this.nowTime.push(moment().format("HH:mm:ss"));
    this.nowTime.push(moment().add(10, "minute").format("HH:mm:ss"));

    this.$store.commit("changeMenuType", "BUY");
    this.getBIContract();
    this.getAllCategory();
    if (this.$route.query.isEdit === "true") {
      this.getSelectBiddingDetails();
      // this.getMyAuthority();
    }
  },
  methods: {
    valueNumber(e, val) {
      e.target.value = e.target.value.replace(/[\u4E00-\u9FA5]/g, "");
      //赋值
      this.publishForm[val] = e.target.value;
    },
    valueChange(e, val) {
      console.log(e.target.value);
      e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;

      // 只能是数字和小数点，不能是其他输入
      e.target.value = e.target.value.replace(reg, "");

      // 保证第一位只能是数字，不能是点
      e.target.value = e.target.value.replace(/^\./g, "");
      // 小数只能出现1位
      e.target.value = e.target.value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // 小数点后面保留2位
      e.target.value = e.target.value.replace(
        /^(\-)*(\d+)\.(\d\d).*$/,
        "$1$2.$3"
      );
      //赋值
      this.publishForm.arbAdditionInfo[val] = e.target.value;
    },
    resetData() {
      this.deliverSetList = false;
      this.paySetList = false;
      this.publishForm = {
        contractNumber: "",
        bidName: "",
        categoryId: "",
        bidPrice: 0,
        startTime: "",
        endTime: "",
        dateTime: "",
        contacts: "",
        phone: "",
        contractInfo: "",
        deliveryAddress: "",
        bidRequire: "",
        delRequire: "",
        pricRequire: "",
        qualRequire: "",
        taxRate: "",
        deliveryDate: "",
        orderProcess: "HK",
        proList: [],
        payMode: [],
        bankBillTerm: "",
        invoiceType: "",

        //付款分期表格
        payDeliverRateFkList: [],
        //交货分期表格
        payDeliverRateJhList: [],
        arbAdditionInfo: {
          fqTermIndex: "",
          type: "BI", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "KQ", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
        },
      };
      this.userAddressList = [];
      this.productManage = {
        form: {
          productName: "",
          productBrand: "",
          productType: "",
          specification: "",
          number: "",
          unit: "",
          unitPrice: "",
          totalPrice: "",
          require: "",
        },
        formRule: {
          productName: [
            { required: true, message: "请输入产品名称", trigger: "blur" },
          ],
          productBrand: [
            { required: true, message: "请输入产品品牌", trigger: "blur" },
          ],
          productType: [
            { required: true, message: "请输入产品型号", trigger: "blur" },
          ],
          specification: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          number: [
            { required: true, validator: validateNumber, trigger: "blur" },
          ],
          unit: [{ required: true, message: "请选择单位", trigger: "blur" }],
          unitPrice: [
            {
              required: true,
              validator: validateUnitPrice,
              msg: "请输入单价",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        editIndex: "",
        dialogVisible: false,
      };
      this.contractList = [];
      this.contractDialogVisible = false;
      this.contractTemplate = "";
      this.tenderDetail = null;
      this.paymentmethod.orderProcess = "";
      this.paymentmethod.payMode = "";
      this.paymentmethod.bankBillTerm = "";
    },
    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.publishForm.orderProcess == "KH") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "SK";
      }
    },
    paySetMethod(newVal) {
      console.log("paySetMethod", newVal);
      if (newVal == "QK" && this.publishForm.orderProcess == "HK") {
        this.publishForm.arbAdditionInfo.paySetPort = "YS";
      }
    },
    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      this.publishForm.payDeliverRateFkList = [];
      this.publishForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        let obj = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          payMode: "",

          type: "FK",
        };

        this.publishForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };

        this.publishForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.publishForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.publishForm.payDeliverRateFkList[val].termIndex = value;
    },
    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },

    addPayList() {
      if (parseInt(this.order) < 10) {
        this.order = this.order + 1;
        for (var i = 0; i < 1; i++) {
          var obj = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",

            type: "FK",
          };
          this.publishForm.payDeliverRateFkList.push(obj);

          var obj1 = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",

            type: "JH",
          };
          this.publishForm.payDeliverRateJhList.push(obj1);
        }
      }
    },

    getTime() {
      let date = new Date();

      let h = date.getHours(),
        m = date.getMonth(),
        s = date.getSeconds();

      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
        s < 10 ? "0" + s : s
      }`;
    },

    next() {
      let isEdit = this.$route.query.isEdit === "true";
      // if (localStorage.getItem('userPermission').indexOf('client:bid:push') ==-1) {
      //   return this.$message.info("暂无修改权限，请联系管理员");
      // }
      console.log(this.examineShow);
      if (this.examineShow == true) {
        this.$emit("nextShow");
      } else {
        this.$refs.publishForm.validate((valid) => {
          if (valid) {
            if (
              formatNewDate(this.publishForm.startTime).getTime() <=
              new Date().getTime()
            ) {
              return this.$message.warning(
                "有效期开始时间的设置需要大于当前时间！"
              );
            }
            // this.$confirm(
            //   // `此操作将${isEdit ? "发布" : "修改"}竞标信息, 是否继续?`,
            //   // `立即${isEdit ? "发布" : "修改"}`,
            //   // {
            //   //   confirmButtonText: "确定",
            //   //   cancelButtonText: "取消",
            //   //   type: "warning",
            //   // }
            // ).then(() => {
            this.publishForm.bidPrice = this.bidTotalPrice;
            this.$emit("next", JSON.parse(JSON.stringify(this.publishForm)));

            if (isEdit) {
              this.updateCompetitive();
            } else {
              if (this.isbidId == 0) {
                this.addCompetitive();
              } else {
                this.updateCompetitive();
              }
            }
            // });
          }
        });
      }
    },
    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },
    // async getMyAuthority() {
    //   let res = await myAuthority({
    //     isPermissions: "client:bid:push",
    //   });
    //   if (res.errno == 0) {
    //     this.examineShow = res.data;
    //   }
    // },
    //回显
    getSelectBiddingDetails() {
      getSelectBiddingDetails({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.errno == 0) {
          this.tenderDetail = res.data;
          Object.keys(this.publishForm).forEach((key) => {
            this.publishForm[key] = res.data[key];
          });
          this.publishForm.dateTime = [res.data.startTime, res.data.endTime];

          if (res.data.list !== undefined) {
            res.data.list.forEach((item) => (item.require = item.tbpRequire));
          }
          this.publishForm.proList = res.data.list;

          this.publishForm.arbAdditionInfo.taxRate =
            res.data.arbAdditionInfo.taxRate.toString();
          // 去掉付款方式的提示语句
          // let index = this.publishForm.delRequire.indexOf("</p>");
          // this.publishForm.delRequire =
          //   this.publishForm.delRequire.substr(index);

          if (
            res.data.arbAdditionInfo.payMode &&
            res.data.arbAdditionInfo.payMode.indexOf("|") != -1
          ) {
            this.publishForm.payMode =
              res.data.arbAdditionInfo.payMode.split("|");
          } else {
            // console.log("res.data.payMode", [res.data.payMode]);
            console.log("找不到||||", res.data);
            if (res.data.arbAdditionInfo.payMode == "") {
              this.publishForm.payMode = [];
            } else {
              this.publishForm.payMode = [res.data.arbAdditionInfo.payMode];
            }
          }

          if (
            res.data.arbAdditionInfo.fqTermIndex !== "" &&
            res.data.arbAdditionInfo.paySet == "FQ"
          ) {
            this.paySetList = true;
          }
          if (
            res.data.arbAdditionInfo.fqJhTermIndex !== "" &&
            res.data.arbAdditionInfo.deliverSet == "FQ"
          ) {
            this.deliverSetList = true;
          }

          if (
            res.data.arbAdditionInfo.qualityTerm !== "" &&
            res.data.arbAdditionInfo.qualityTerm !== undefined
          ) {
            res.data.arbAdditionInfo.qualityTerm =
              res.data.arbAdditionInfo.qualityTerm.toString();
          } else {
            this.publishForm.arbAdditionInfo.qualityTerm = "";
          }

          if (
            res.data.arbAdditionInfo.deliverModeDay !== "" &&
            res.data.arbAdditionInfo.deliverModeDay !== undefined
          ) {
            res.data.arbAdditionInfo.deliverModeDay =
              res.data.arbAdditionInfo.deliverModeDay.toString();
          } else {
            this.publishForm.arbAdditionInfo.deliverModeDay = "";
          }

          //百分比
          if (res.data.arbAdditionInfo.payPercent == "") {
            this.$set(this.publishForm.arbAdditionInfo, "yhzzPercentage", "");
            this.$set(this.publishForm.arbAdditionInfo, "cdhpPercentage", "");
            this.$set(this.publishForm.arbAdditionInfo, "yhhpPercentage", "");
            this.$set(this.publishForm, "bankBillTerm", "");
          } else {
            let percentageArr = res.data.arbAdditionInfo.payPercent.split("|");
            console.log("percentageArr", percentageArr);
            if (percentageArr[0] == "0" && percentageArr[0] !== undefined) {
              this.publishForm.arbAdditionInfo.yhzzPercentage = "0";
              this.$set(
                this.publishForm.arbAdditionInfo,
                "cdhpPercentage",
                percentageArr[1]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhhpPercentage",
                percentageArr[2]
              );
            } else if (
              percentageArr[1] == "0" &&
              percentageArr[1] !== undefined
            ) {
              console.log("bbbbbb");

              this.publishForm.arbAdditionInfo.cdhpPercentage = "0";
              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhhpPercentage",
                percentageArr[2]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhzzPercentage",
                percentageArr[0]
              );
            } else if (
              percentageArr[2] == "0" &&
              percentageArr[2] !== undefined
            ) {
              console.log("cccccc");

              this.publishForm.arbAdditionInfo.yhhpPercentage = "0";
              this.$set(
                this.publishForm.arbAdditionInfo,
                "cdhpPercentage",
                percentageArr[1]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhzzPercentage",
                percentageArr[0]
              );
            } else {
              this.$set(
                this.publishForm.arbAdditionInfo,
                "cdhpPercentage",
                percentageArr[1]
              );

              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhzzPercentage",
                percentageArr[0]
              );
              this.$set(
                this.publishForm.arbAdditionInfo,
                "yhhpPercentage",
                percentageArr[2]
              );
            }
          }

          if (
            res.data.arbAdditionInfo.paySet == "QK" &&
            res.data.arbAdditionInfo.deliverSet == "FQ"
          ) {
            (this.deliverSetList = true),
              (this.publishForm.payDeliverRateFkList =
                res.data.payDeliverRateJhList.map((item) => {
                  return {
                    termIndex: item.termIndex,
                    rate: "",
                    date: "",
                    payMode: "",
                    type: "FK",
                  };
                }));
          }

          if (
            res.data.arbAdditionInfo.deliverSet == "QK" &&
            res.data.arbAdditionInfo.paySet == "FQ"
          ) {
            (this.paySetList = true),
              (this.publishForm.payDeliverRateJhList =
                res.data.payDeliverRateFkList.map((item) => {
                  return {
                    termIndex: item.termIndex,
                    rate: "",
                    date: "",
                    payMode: "",
                    type: "JH",
                  };
                }));
          }

          if (
            res.data.arbAdditionInfo.deliverSet == "QK" &&
            res.data.arbAdditionInfo.paySet == "QK"
          ) {
            this.publishForm.payDeliverRateFkList = [
              {
                termIndex: "",
                rate: "",
                date: "",
                payMode: "",
                type: "FK",
              },
            ];

            this.publishForm.payDeliverRateJhList = [
              {
                termIndex: "",
                rate: "",
                date: "",
                payMode: "",
                type: "JH",
              },
            ];
            // }
          }
        }
      });
    },
    addProduct() {
      this.$refs.productForm.validate((valid) => {
        if (valid) {
          if (!this.productManage.form.unit) {
            return this.$message.warning("请选择单位");
          }
          if (
            this.productManage.form.number *
              this.productManage.form.unitPrice >=
            10000000000
          ) {
            return this.$message.warning("总价需小于 10000000000 元");
          }

          this.productManage.form.totalPrice =
            this.productManage.form.number * this.productManage.form.unitPrice;
          // -----------

          // -----------

          if (!this.productManage.isEdit) {
            this.publishForm.proList.push({ ...this.productManage.form });
          } else {
            this.publishForm.proList.splice(this.productManage.editIndex, 1, {
              ...this.productManage.form,
            });
          }

          this.productManage.dialogVisible = false;
        }
      });
    },

    addCompetitive() {
  
      this.publishForm.payMode = this.publishForm.payMode
        .toString()
        .replaceAll(",", "|");
      // console.log('  this.publishForm.payMode ' , this.publishForm.payMode );

      this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
      console.log(
        "this.publishForm.arbAdditionInfo.payMode",
        this.publishForm.arbAdditionInfo.payMode
      );
      console.log(" this.publishForm.payMode", this.publishForm.payMode);
      //百分比

      let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
      let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
      let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

      if (this.publishForm.payMode == [] || this.publishForm.payMode == "") {
        this.publishForm.arbAdditionInfo.payPercent = "";
      } else if (
        this.publishForm.payMode.length == 14 ||
        this.publishForm.payMode.length == 9
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        if (CDHPper == "") {
          CDHPper = "0";
        }
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + CDHPper + "|" + YHHPper;
        console.log("payPercent1");
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHZZ") !== -1
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + "0" + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        if (CDHPper == "") {
          CDHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + CDHPper + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHHP") !== -1
      ) {
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + "0" + "|" + YHHPper;
      }

      this.publishForm.arbAdditionInfo.bankBillTerm =
        this.publishForm.bankBillTerm;
      let publishForm = { ...this.publishForm };
      // publishForm.delRequire = this.addPrompt();
      publishForm.categoryId =
        publishForm.categoryId[publishForm.categoryId.length - 1];

      addCompetitive({
        ...publishForm,
        steps: "0",
      }).then((res) => {
        if (res.errno === 0) {
          // this.$emit("next",this.publishForm);

          // this.$message({
          //   type: "success",
          //   message: "发布成功！",
          // });
          //   this.$router.push({
          //     path: "/User/MyCompetitive",
          //   });
          this.bidId = res.data.bidId;
          this.$emit("getBidId", this.bidId);
        }
      });
    },
    updateCompetitive() {
      this.publishForm.payMode = this.publishForm.payMode
        .toString()
        .replaceAll(",", "|");
      // console.log('  this.publishForm.payMode ' , this.publishForm.payMode );

      this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
      //百分比

      let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
      let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
      let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

      if (this.publishForm.payMode == [] || this.publishForm.payMode == "") {
        this.publishForm.arbAdditionInfo.payPercent = "";
      } else if (
        this.publishForm.payMode.length == 14 ||
        this.publishForm.payMode.length == 9
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        if (CDHPper == "") {
          CDHPper = "0";
        }
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + CDHPper + "|" + YHHPper;
        console.log("payPercent1");
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHZZ") !== -1
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + "0" + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        if (CDHPper == "") {
          CDHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + CDHPper + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHHP") !== -1
      ) {
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + "0" + "|" + YHHPper;
      }

      this.publishForm.arbAdditionInfo.bankBillTerm =
        this.publishForm.bankBillTerm;

      let publishForm = { ...this.publishForm };

      // console.log('11',this.isbidId==0&&this.isEdit!==true?"a":"b" );
      // publishForm.categoryId =
      //   publishForm.categoryId[publishForm.categoryId.length - 1];
      updateCompetitive({
        id:
          this.isbidId == 0 && this.isEdit !== true
            ? this.$route.query.id
            : this.isbidId,
        steps: "0",
        ...publishForm,
        categoryId: Array.isArray(this.publishForm.categoryId)
          ? this.publishForm.categoryId[this.publishForm.categoryId.length - 1]
          : this.publishForm.categoryId,
      }).then((res) => {
        if (res.errno === 0) {
          // this.$emit("next",this.publishForm);
          //   this.$emit("next", JSON.parse(JSON.stringify(this.publishForm)));
          // this.$message({
          //   type: "success",
          //   message: "修改成功！",
          // });
          //   this.$router.push({
          //     path: "/User/MyCompetitive",
          //   });
          if (this.$route.query.id !== undefined) {
            this.bidId = this.$route.query.id;
            this.$emit("getBidId", this.bidId);
          } else {
            console.log("lastid", this.lastid);
            this.bidId = this.lastid;
            this.$emit("getBidId", this.bidId);
          }
        }
      });
    },
    getBIContract() {
      getBIContract({
        page: 1,
        limit: 100,
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
    onValidityDateTimeChange(value) {
      this.publishForm.startTime = value[0];
      this.publishForm.endTime = value[1];
    },
    onBlurTaxRate(value) {
      if (isNaN(value)) return;
      this.publishForm.taxRate = Number(value).toFixed(2);
    },
    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.contractTextModel;
        }
      });
      this.contractDialogVisible = true;
    },
    showProductDialog(index, row) {
      this.productManage.form.unit = "";
      if (row) {
        this.productManage.isEdit = true;
        this.productManage.editIndex = index;
        Object.keys(row).forEach(
          (key) => (this.productManage.form[key] = row[key])
        );
      } else {
        this.productManage.isEdit = false;
      }

      this.productManage.dialogVisible = true;
    },
    // validateStartTime(rule, value, callback) {
    //   if (!value) {
    //     return callback(new Error("请选择有效期"));
    //   } else if (formatNewDate(value[0]).getTime() < Date.now()) {
    //     return callback(new Error("开始时间不能早于当前时间"));
    //   } else if (
    //     formatNewDate(value[1]).getTime() - formatNewDate(value[0]).getTime() >
    //     1000 * 60 * 60 * 24 * 30
    //   ) {
    //     return callback(new Error("有效期时间不能大于 30 天"));
    //   } else {
    //     callback();
    //   }
    // },
    //  表格上传
    beforeAvatarUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      //
      if (!isXlsx) {
        this.$message.error("导入表格文件只能是 .xlsx格式!");
      }
      return isXlsx;
    },
    handleAvatarSuccess(res, file) {
      if (res.errno === 0) {
        this.publishForm.proList = res.data;
        // this.publishForm.proList.map(item=>{
        //   item.totalPrice
        // })
      } else {
        this.$message.error(res.errmsg);
      }
    },
    // 根据选择添加付款方式和提示进合同
    // addPrompt() {
    //   let prompt = this.publishForm;
    //   if (prompt.orderProcess == 'HK') {
    //     if (prompt.payMode == 'YHZZ') {
    //       return `<p>付款方式：银行转账</p>` + prompt.delRequire;
    //     } else {
    //       return `<p>付款方式：商业承兑汇票，承兑期限为${prompt.bankBillTerm}个月</p>` + prompt.delRequire;
    //     }
    //   } else {
    //     if (prompt.payMode == 'YHZZ') {
    //       return `<p>付款方式：银行转账，签订合同后3天以内以银行转账的方式一次性付清</p>` + prompt.delRequire;
    //     } else {
    //       return `<p>付款方式：商业承兑汇票，在签订合同后3天以内以承兑期限为${prompt.bankBillTerm}个月的商业承兑汇票一次性付清</p>` + prompt.delRequire;
    //     }
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
.publish-container {
  background: #fff;
  padding-bottom: 20px;
  .stepNav {
    margin-left: 28px;
    padding: 18px 0;
    border-bottom: 1px solid #f5f7fc;
  }
  .form-content {
    padding-top: 20px;
    margin: 0 auto;
    width: 80%;
    margin-bottom: 30px;
    position: relative;
    .btnBoxStyle {
      .btnstyle {
        position: absolute;
        right: 50%;
        z-index: 15;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    // el-date-picker el-popper has-time
    .basicBox {
      position: relative;
      margin-top: 20px;
      .basic {
        border-top: 1px solid #eeeeee;
        padding-top: 38px;
        z-index: 1;
      }
      .titleFont {
        position: absolute;
        top: -15px;

        font-size: 18px;
        color: #a7b1c1;
        width: 80px;
        background-color: #fff;
        z-index: 2;
        margin-bottom: -5px;
      }
    }

    .input-width {
      width: 300px;
      font-size: 12px;
    }
    .input-width-small {
      width: 60px;
    }
    .table-input-width {
      width: 250px;
      margin-top: 20px;
    }
    .capital {
      margin-top: 15px;
    }
    .form-item {
      margin-right: 0;
      margin-bottom: 30px;
      width: 50%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .form-item-invsStatus {
      // margin-right: 200px;
      margin-left: 70px;
      margin-bottom: 30px;
      width: 34%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .avatar {
      width: 148px;
      height: 148px;
      display: block;
    }
    .select-style {
      width: 300px;
      .el-select-dropdown__item {
        height: auto;
      }
    }
  }
}

/deep/ #contract-id {
  // background-color: skyblue !important;
  white-space: normal;
  overflow: visible;
  height: 70px;
  margin-top: 10px;
  line-height: 20px;
  z-index: 9999;
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      display: block;
      word-wrap: break-word;
      top: 5px;
      left: 0;
    }
    .num {
      display: block;
      bottom: -48px;
      left: 0;
    }
  }
}
.product-content {
  margin-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .upbtn {
      display: flex;

      .upload-demo {
        margin: 0px 8px;
      }
    }
  }
}
.product-list {
  .input-width {
    width: 280px;
  }
}
/deep/.el-form-item__label {
  font-size: 12px;
  padding: 0;
}

/deep/.mce-tinymce {
  box-shadow: none;
}
</style>
