import { render, staticRenderFns } from "./deliverGoods.vue?vue&type=template&id=712221b1&scoped=true&"
import script from "./deliverGoods.vue?vue&type=script&lang=js&"
export * from "./deliverGoods.vue?vue&type=script&lang=js&"
import style0 from "./deliverGoods.vue?vue&type=style&index=0&id=712221b1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "712221b1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xian\\work\\datacockpit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('712221b1')) {
      api.createRecord('712221b1', component.options)
    } else {
      api.reload('712221b1', component.options)
    }
    module.hot.accept("./deliverGoods.vue?vue&type=template&id=712221b1&scoped=true&", function () {
      api.rerender('712221b1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/User/components/competitive/PublishCompetitive/components/deliverGoods.vue"
export default component.exports