<template>
  <div class="publish-container">
    <!-- <el-alert
      v-if="tenderDetail && tenderDetail.apprvStatus === 'F'"
      :title="tenderDetail.apprvRemark"
      type="error"
      show-icon
    >
    </el-alert> -->
    <div class="form-content">
      <!-- 遮罩 -->
      <div class="transparentBox" v-if="examineShow"></div>
      <!-- {{ publishForm }} \\\\\{{ bidId }} -->

      <el-form
        inline
        :model="publishForm"
        :rules="publishFormRules"
        ref="publishForm"
        label-width="130px"
      >
        <el-row>
          <el-form-item
            class="form-item"
            label="包装方式："
            prop="arbAdditionInfo.packageMode"
          >
            <el-select
              class="select-style"
              v-model="publishForm.arbAdditionInfo.packageMode"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in paymentmethod.packageModeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="验收条件：" prop="arbAdditionInfo.checkMode">
            <el-select
              class="select-style"
              v-model="publishForm.arbAdditionInfo.checkMode"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in paymentmethod.checkModeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item
            class="form-item"
            label="交货日期："
            prop="deliveryDate"
          >
            <el-date-picker
              size="small"
              class="input-width"
              v-model="publishForm.deliveryDate"
              value-format="yyyy-MM-dd"
              :picker-options="deliveryPickerStartOptions"
              type="date"
              placeholder="请选择交货日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-row>

        <el-form-item
          class="form-item-invsStatus"
          label="交货方式："
          prop="arbAdditionInfo.deliverMode"
        >
          <el-radio-group v-model="publishForm.arbAdditionInfo.deliverMode">
            <el-radio label="SH">供应商送货</el-radio>
            <el-radio label="ZT">买家自提</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          class="form-item-invsStatus"
          prop="arbAdditionInfo.deliverModeDay"
          :style="{
            color:
              publishForm.arbAdditionInfo.deliverMode == 'ZT'
                ? '#000'
                : '#c0c4cc',
          }"
        >
          <div style="font-size: 14px">
            卖方通知提货之日起【<el-input
              v-model="publishForm.arbAdditionInfo.deliverModeDay"
              class="input-width-small"
              size="mini"
              :disabled="
                publishForm.arbAdditionInfo.deliverMode == 'ZT' ? false : true
              "
            >
            </el-input
            >】个工作日内提货
          </div>
        </el-form-item>

        <el-row>
          <el-form-item
            class="form-item-invsStatus"
            label="质保方式："
            prop="arbAdditionInfo.qualityMode"
          >
            <el-radio-group v-model="publishForm.arbAdditionInfo.qualityMode">
              <el-radio label="GZB">供应商质保</el-radio>
              <el-radio label="ORTHER">其他</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            class="form-item-invsStatus"
            prop="arbAdditionInfo.qualityTerm"
            :style="{
              color:
                publishForm.arbAdditionInfo.qualityMode == 'GZB'
                  ? '#000'
                  : '#c0c4cc',
            }"
          >
            <div style="font-size: 14px">
              质保期为通过验收之日起【<el-input
                class="input-width-small"
                v-model="publishForm.arbAdditionInfo.qualityTerm"
                size="mini"
                :disabled="
                  publishForm.arbAdditionInfo.qualityMode == 'GZB'
                    ? false
                    : true
                "
              ></el-input
              >】年
            </div>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item
            class="form-item"
            label="交货安排："
            prop="arbAdditionInfo.deliverSet"
          >
            <el-radio-group
              v-model="publishForm.arbAdditionInfo.deliverSet"
              @change="deliverSetMethod(publishForm.arbAdditionInfo.deliverSet)"
            >
              <el-radio label="QK">一次性交货</el-radio>
              <el-radio label="FQ">分期交货</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            class="form-item"
            v-if="publishForm.arbAdditionInfo.deliverSet == 'FQ'"
            label="分期交货："
            prop="arbAdditionInfo.fqJhTermIndex"
          >
            <el-input
              class="input-width"
              v-model="publishForm.arbAdditionInfo.fqJhTermIndex"
              placeholder="1-24期"
              size="mini"
              @input="inputChange(publishForm.arbAdditionInfo.fqJhTermIndex)"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              maxlength="2"
            >
            </el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item
            class="form-item-deliverSetDay"
            v-if="publishForm.arbAdditionInfo.deliverSet == 'QK'"
            label="发货要求："
            prop="arbAdditionInfo.deliverSetPort"
          >
            <el-select
              class="input-width"
              v-model="publishForm.arbAdditionInfo.deliverSetPort"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in paymentmethod.arrangementDelivery"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="form-item-deliverSetDay"
            v-if="
              publishForm.arbAdditionInfo.deliverSet == 'QK' &&
              publishForm.arbAdditionInfo.deliverSetPort == 'QD'
            "
            prop="arbAdditionInfo.deliverSetDay"
          >
            <div style="font-size: 14px">
              签订合同之日起【<el-input
                class="input-width-small"
                v-model="publishForm.arbAdditionInfo.deliverSetDay"
                size="mini"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input
              >】个工作日内
            </div>
          </el-form-item>
          <el-form-item
            class="form-item"
            v-if="
              publishForm.arbAdditionInfo.deliverSet == 'QK' &&
              publishForm.arbAdditionInfo.deliverSetPort == 'SK'
            "
            prop="arbAdditionInfo.deliverSetDay"
          >
            <div style="font-size: 14px">
              收到全部贷款之日起【<el-input
                class="input-width-small"
                v-model="publishForm.arbAdditionInfo.deliverSetDay"
                size="mini"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input
              >】个工作日内
            </div>
          </el-form-item></el-row
        >
        <el-table
          :data="publishForm.payDeliverRateJhList"
          highlight-current-row
          border
          align="center"
          v-if="
            publishForm.arbAdditionInfo.deliverSet == 'FQ' && deliverSetList
          "
          style="width: 100%; margin-bottom: 30px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: 0 + 'px' }"
        >
          <el-table-column prop="termIndex" label="期数">
            <template slot-scope="scope">
              第{{ scope.row.termIndex }}期
            </template>
          </el-table-column>

          <el-table-column prop="rate" label="交货比例">
            <template slot-scope="scope">
              <el-form-item
                :prop="'payDeliverRateJhList.' + scope.$index + '.rate'"
                :rules="publishFormRules.JHrate"
              >
                <el-input
                  class="table-input-width"
                  v-model="scope.row.rate"
                  size="mini"
                  oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column prop="date" label="支付时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="'payDeliverRateJhList.' + scope.$index + '.date'"
                :rules="publishFormRules.JHdate"
              >
                <el-date-picker
                  size="mini"
                  class="table-input-width"
                  v-model="scope.row.date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  :picker-options="deliveryPickerStartOptions"
                  placeholder="请选择开始时间"
                >
                </el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>

        <!-- ——--———————————————————————————————————————————————————————————————————— -->

        <div class="btnBoxStyle">
          <el-button class="btnLast" type="primary" @click="last"
            >上一步</el-button
          >

          <el-button
            class="btnNext"
            type="primary"
            v-if="this.examineShow !== true"
            @click="next"
            >{{
              $route.query.isEdit === "true" ? "修改" : "立即发布"
            }}</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- 查看合同模板 -->
    <el-dialog
      title="合同模板"
      :visible.sync="contractDialogVisible"
      width="700px"
    >
      <div v-html="contractTemplate"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBIContract,
  categoryAll,
  addCompetitive,
  updateCompetitive,
  getSelectBiddingDetails,
  importExcel,
  getContractInfo,
} from "../../../../api";
import { formatNewDate } from "@/utils/util";
import tinymceEditor from "@/components/tinymceEditor.vue";
import Title from "../../../../component/Title";
import { regRule } from "@/utils/validate";

export default {
  components: {
    Title,
    tinymceEditor,
  },
  props: {
    publishForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bidId: {
      type: String,
      default: () => {
        return {};
      },
    },
    examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // $route: function (to) {
    //   console.log(to);
    //   if (Object.keys(to.query).length == 0) {
    //     this.resetData();
    //   }
    // },
    bidId: {
      handler(val) {
        this.id = val;
        console.log("idd", this.id);
      },
      immediate: true,
    },

    "publishForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.publishForm.arbAdditionInfo.invsStatus = "KH";
      }
    },

    // "publishForm.arbAdditionInfo.paySet": {
    //   handler(newVal, oldVal) {
    //     console.log(newVal + "--" + oldVal);
    //     if (newVal == "FQ") {
    //       this.publishForm.arbAdditionInfo.paySetPort = "";
    //     }
    //     if (
    //       newVal == "FQ" &&
    //       oldVal == "QK" &&
    //       this.publishForm.arbAdditionInfo.fqTermIndex !== ""
    //     ) {
    //       this.paySetList = true;
    //     }
    //     if (
    //       newVal == "FQ" &&
    //       this.publishForm.arbAdditionInfo.fqTermIndex !== ""
    //     ) {
    //       this.paySetList = true;
    //     }
    //   },
    //   immediate: true,
    // },

    "publishForm.arbAdditionInfo.deliverSet": {
      handler(newVal, oldVal) {
        console.log(newVal + "--111" + oldVal);
        if (newVal == "FQ") {
          this.publishForm.arbAdditionInfo.deliverSetPort = "";
          this.publishForm.arbAdditionInfo.deliverSetDay = "";
        }
        if (
          newVal == "FQ" &&
          oldVal == "QK" &&
          this.publishForm.arbAdditionInfo.fqTermIndex !== ""
        ) {
          if (Number(this.publishForm.arbAdditionInfo.fqJhTermIndex) < 25) {
            this.deliverSetList = true;
          }
        }
        if (
          newVal == "FQ" &&
          this.publishForm.arbAdditionInfo.fqJhTermIndex !== ""
        ) {
          if (Number(this.publishForm.arbAdditionInfo.fqJhTermIndex) < 25) {
            this.deliverSetList = true;
          }
        }
      },
      immediate: true,
    },
    //自提
    "publishForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      if (newVal == "SH" && oldVal == "") {
        console.log("2333333333333");
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
      if (newVal == "SH" && oldVal == "ZT") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
      // if (newVal == "ZT" && oldVal == "SH") {
      //   this.publishForm.arbAdditionInfo.deliverModeDay = "";
      // }
    },
    "publishForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
      // if (newVal == "GZB" && oldVal == "ORTHER") {
      //   this.publishForm.arbAdditionInfo.qualityTerm = "";
      // }
    },
    "publishForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      if (newVal == "KQ" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsDay = "";
      }
    },
    "publishForm.payMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);

      if (
        // this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        // this.publishForm.payMode.indexOf("CDHP") == -1
        this.publishForm.payMode.length == 1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        console.log("长度是1");
        this.publishForm.arbAdditionInfo.yhzzPercentage = "100";
        this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      }
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.length == 1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage = "100";
        this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      }
    },
  },
  data() {
    // let validatePrice = (rule, value, callback) => {
    //   if (!value && value !== 0) {
    //     return callback(new Error(rule.msg));
    //   } else if (!regRule.number1.test(value)) {
    //     return callback(new Error("请输入正确的报价"));
    //   } else if (value >= 100000000) {
    //     return callback();
    //   } else {
    //     callback();
    //   }
    // };
    let validateUnitPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入单价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("请输入正确的报价"));
      } else if (value >= 10000000000) {
        return callback(new Error("价格调整幅度需小于 10000000000 元"));
      } else {
        callback();
      }
    };
    let validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else {
        callback();
      }
    };
    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var validateStartTime = (rule, value, callback) => {
      console.log(new Date(value).getTime());
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value).getTime() < Date.now() - 1000) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.qualityMode !== "GZB") {
        return callback();
      } else {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          this.publishForm.arbAdditionInfo.qualityTerm = "";
          return callback(new Error("请输入 1 ~ 180之间的质保期限"));
        } else if (value.toString().indexOf(".") !== -1) {
          this.publishForm.arbAdditionInfo.qualityTerm = "";
          return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
        } else if (value.length !== 1 && value[0] == 0) {
          this.publishForm.arbAdditionInfo.qualityTerm = "";
          return callback(new Error("请输入 1 ~ 180之间的质保期限"));
        } else {
          callback();
        }
      }
    };
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入采购量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 6
      ) {
        return callback(new Error("最多保留六位小数"));
      } else {
        callback();
      }
    };

    var validatePayAfterData = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.deliverMode !== "ZT") {
        return callback();
      } else {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else {
          callback();
        }
      }
    };
    var validateDeliverSetDay = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.deliverSet !== "QK") {
        return callback();
      } else {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          this.publishForm.arbAdditionInfo.deliverSetDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") !== -1) {
          this.publishForm.arbAdditionInfo.deliverSetDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        }
        {
          callback();
        }
      }
    };

    var validateDeliverModeDay = (rule, value, callback) => {
      if (this.publishForm.arbAdditionInfo.deliverMode !== "ZT") {
        return callback();
      } else {
        if (!value || isNaN(value) || value <= 0 || value > 180) {
          this.publishForm.arbAdditionInfo.deliverModeDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") !== -1) {
          this.publishForm.arbAdditionInfo.deliverModeDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        }
        {
          callback();
        }
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.publishForm.payMode.indexOf("CDHP"));
      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validatefqTermIndex = (rule, value, callback) => {
      console.log("第一", value);
      // console.log("第二", this.publishForm.arbAdditionInfo.fqTermIndex);
      // console.log(
      //   "payDeliverRateFkList",
      //   typeof this.publishForm.payDeliverRateFkList.length
      // );
      // console.log(
      //   this.publishForm.payDeliverRateFkList.length !== parseFloat(value)
      // );

      // if (this.publishForm.payDeliverRateFkList.length !== parseFloat(value)) {
      //   return callback(new Error("交货分期需要与付款分期一致"));
      // }
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }

      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };
    var validatefqJhTermIndex = (rule, value, callback) => {
      console.log(value);
      console.log(
        "payDeliverRateFkList",
        this.publishForm.payDeliverRateFkList
      );
      console.log(
        "payDeliverRateJhList",
        this.publishForm.payDeliverRateJhList
      );
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        return callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      if (
        value !== this.publishForm.arbAdditionInfo.fqTermIndex &&
        this.publishForm.arbAdditionInfo.paySet == "FQ"
      ) {
        return callback(new Error("交货分期需要与付款分期一致"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };
    return {
      deliverSetList: false,
      paySetList: false,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      pickerStartOptions: {
        disabledDate: (time) => {
          return (
            time.getTime() < Date.now() - 1000 * 60 * 60 * 24 ||
            time.getTime() - Date.now() > 1000 * 60 * 60 * 24 * 31
          );
        },
      },
      deliveryPickerStartOptions: {
        disabledDate: (time) => {
          if (this.publishForm.dateTime) {
            let date = formatNewDate(this.publishForm.dateTime[1]);
            return (
              time.getTime() <
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).getTime()
            );
          } else {
            let date = new Date();
            return (
              time.getTime() <
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).getTime()
            );
          }
        },
      },
      //   publishForm: {
      //     bidName: "",
      //     categoryId: "",
      //     bidPrice: 0,
      //     startTime: "",
      //     endTime: "",
      //     dateTime: "",
      //     contacts: "",
      //     phone: "",
      //     contractInfo: "",
      //     deliveryAddress: "",
      //     bidRequire: "",
      //     delRequire: "",
      //     pricRequire: "",
      //     qualRequire: "",
      //     taxRate: "",
      //     deliveryDate: "",
      //     orderProcess: "HK",
      //     proList: [],
      //     payMode: [],
      //     bankBillTerm: "",
      //     invoiceType: "",

      //     //付款分期表格
      //     payDeliverRateFkList: [],
      //     //交货分期表格
      //     payDeliverRateJhList: [],
      //     arbAdditionInfo: {
      //       fqTermIndex: "",
      //       type: "BI", //竞卖
      //       taxRate: "", // 使用税率
      //       invsStatus: "KQ", // 开票时间
      //       paySet: "", //付款安排
      //       deliverSet: "", //交付安排
      //       deliverMode: "", // 交货方式
      //       packageMode: "", //包装方式
      //       checkMode: "", //验收条件
      //       qualityMode: "", //质保方式
      //       qualityTerm: "", //质保的日期
      //       paySetPort: "", // 付款安排数据
      //       deliverSetPort: "", // 交付安排数据

      //       invsDay: "", //付款后开发票日期
      //       deliverModeDay: "", //买家自提的日期
      //       bankBillTerm: "", //承兑汇票期限
      //       payMode: "",
      //       //------------------------
      //       paySetDay: "", //付款安排一次性付款日期

      //       //---------------------------
      //       deliverSetDay: "", //交货安排一次性交货日期

      //       //-----------------------------
      //       payPercent: "", // 付款占比
      //       yhzzPercentage: "", //银行转账百分比
      //       cdhpPercentage: "", //承兑汇票百分比
      //     },
      //   },
      userAddressList: [],
      categoryList: [],
      publishFormRules: {
        bidName: [
          { required: true, message: "请输入标的名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        taxRate: [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            pattern: regRule.regPhoneStrict,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
        ],
        // bidPrice: [
        //   {
        //     required: true,
        //     validator: validatePrice,
        //     msg: "请输入标的价格",
        //     trigger: "blur",
        //   },
        // ],
        dateTime: [
          {
            required: true,
            validator: this.validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        deliveryDate: [
          {
            required: true,
            message: "请选择交货时间",
            trigger: ["blur", "change"],
          },
        ],
        deliveryAddress: [
          {
            required: true,
            message: "请输入收货信息",
            trigger: ["blur", "change"],
          },
        ],
        contractInfo: [
          {
            required: true,
            message: "请选择合同模板",
            trigger: ["blur", "change"],
          },
        ],
        orderProcess: [
          {
            required: true,
            message: "请选择订单模式",
            trigger: ["blur", "change"],
          },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validateDeliverSetDay,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validateDeliverModeDay,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,
            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.fqJhTermIndex": [
          {
            required: true,
            validator: validatefqJhTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["blur", "change"],
          },
        ],
      },
      productManage: {
        form: {
          productName: "",
          productBrand: "",
          productType: "",
          specification: "",
          number: "",
          unit: "",
          unitPrice: "",
          totalPrice: "",
          require: "",
        },
        formRule: {
          productName: [
            { required: true, message: "请输入产品名称", trigger: "blur" },
          ],
          productBrand: [
            { required: true, message: "请输入产品品牌", trigger: "blur" },
          ],
          productType: [
            { required: true, message: "请输入产品型号", trigger: "blur" },
          ],
          specification: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          number: [
            { required: true, validator: validateNumber, trigger: "blur" },
          ],
          unit: [{ required: true, message: "请选择单位", trigger: "blur" }],
          unitPrice: [
            {
              required: true,
              validator: validateUnitPrice,
              msg: "请输入单价",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        editIndex: "",
        dialogVisible: false,
      },
      contractList: [],
      contractDialogVisible: false,
      contractTemplate: "",
      tenderDetail: null,
      importExcel,
      paymentmethod: {
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        checkModeData: [
          {
            value: "GZJ",
            label: "卖方自检",
          },
          {
            value: "MZJ",
            label: "买方自检",
          },
          {
            value: "GMJ",
            label: "买卖双方共同检验",
          },
          {
            value: "SFJ",
            label: "第三方检验",
          },
          {
            value: "ORTHER",
            label: "其他",
          },
        ],
        packageModeData: [
          {
            value: "YC",
            label: "原厂包装",
          },
          {
            value: "DK",
            label: "打捆包装",
          },
          {
            value: "ORTHER",
            label: "其他",
          },
        ],
        payListOption: [
          {
            value: "1",
            label: "第1期",
          },
          {
            value: "2",
            label: "第2期",
          },
          {
            value: "3",
            label: "第3期",
          },
          {
            value: "4",
            label: "第4期",
          },
          {
            value: "5",
            label: "第5期",
          },
          {
            value: "6",
            label: "第6期",
          },
          {
            value: "7",
            label: "第7期",
          },
          {
            value: "8",
            label: "第8期",
          },
          {
            value: "9",
            label: "第9期",
          },
          {
            value: "10",
            label: "第10期",
          },
          {
            value: "11",
            label: "第11期",
          },
          {
            value: "12",
            label: "第12期",
          },
          {
            value: "13",
            label: "第13期",
          },
          {
            value: "14",
            label: "第14期",
          },
          {
            value: "15",
            label: "第15期",
          },
          {
            value: "16",
            label: "第16期",
          },
          {
            value: "17",
            label: "第17期",
          },
          {
            value: "18",
            label: "第18期",
          },
          {
            value: "19",
            label: "第19期",
          },
          {
            value: "20",
            label: "第20期",
          },
          {
            value: "21",
            label: "第21期",
          },
          {
            value: "22",
            label: "第22期",
          },
          {
            value: "23",
            label: "第23期",
          },
          {
            value: "24",
            label: "第24期",
          },
        ],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
    };
  },
  computed: {
    // bidTotalPrice() {
    //   let price = 0;
    //   this.publishForm.proList.forEach((item) => {
    //     price += item.totalPrice;
    //   });
    //   return price;
    // },
      RulesShow: function () {
      if (this.examineShow == true) {
        return this.noRules;
      } else {
        return this.publishFormRules;
      }
    },
  },
  created() {
    this.$store.commit("changeMenuType", "BUY");
    this.getBIContract();
    this.getAllCategory();
    if (this.$route.query.isEdit === "true") {
      //   this.getSelectBiddingDetails();
    }
  },
  methods: {
    keyup(e, val) {
      //限制10位整数 与两位小数的正则规则
      if (e.target.value[0] === ".") {
        e.target.value = e.target.value.replace(/^(\.)/, "0$1");
      } else if (e.target.value[0] === "0" && /\d/.test(e.target.value[1])) {
        e.target.value = e.target.value.substring(1);
      } else if (e.target.value.includes(".")) {
        const arr = e.target.value.split(".");
        if (arr[0].length > 10) {
          e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`;
          this.productManage.form[val] = e.target.value;
        } else {
          e.target.value = e.target.value.replace(/[^\d\.]/g, "");
          e.target.value = e.target.value.replace(
            /^(\d{0,10}\.?\d{0,2}).*/,
            "$1"
          );
          this.productManage.form[val] = e.target.value;
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        e.target.value = e.target.value.replace(/^(\d{0,10}).*/, "$1");
        this.productManage.form[val] = e.target.value;
      }
    },
    blurmethod(e, val) {
      if (e.target.value[e.target.value.length - 1] === ".") {
        e.target.value = e.target.value.substring(0, [
          e.target.value.length - 1,
        ]);
        this.productManage.form[val] = e.target.value;
      }

      //  console.log( e.target.value.length==12 &&e.target.value.indexOf('.')==-1);
      //  console.log(e.target.value.indexOf('.')==-1);
    },
    resetData() {
      this.deliverSetList = false;
      this.paySetList = false;
      this.publishForm = {
        bidName: "",
        categoryId: "",
        bidPrice: 0,
        startTime: "",
        endTime: "",
        dateTime: "",
        contacts: "",
        phone: "",
        contractInfo: "",
        deliveryAddress: "",
        bidRequire: "",
        delRequire: "",
        pricRequire: "",
        qualRequire: "",
        taxRate: "",
        deliveryDate: "",
        orderProcess: "HK",
        proList: [],
        payMode: [],
        bankBillTerm: "",
        invoiceType: "",

        //付款分期表格
        payDeliverRateFkList: [],
        //交货分期表格
        payDeliverRateJhList: [],
        arbAdditionInfo: {
          fqTermIndex: "",
          type: "BI", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "KQ", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
        },
      };
      this.userAddressList = [];
      this.productManage = {
        form: {
          productName: "",
          productBrand: "",
          productType: "",
          specification: "",
          number: "",
          unit: "",
          unitPrice: "",
          totalPrice: "",
          require: "",
        },
        formRule: {
          productName: [
            { required: true, message: "请输入产品名称", trigger: "blur" },
          ],
          productBrand: [
            { required: true, message: "请输入产品品牌", trigger: "blur" },
          ],
          productType: [
            { required: true, message: "请输入产品型号", trigger: "blur" },
          ],
          specification: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          number: [
            { required: true, validator: validateNumber, trigger: "blur" },
          ],
          unit: [{ required: true, message: "请选择单位", trigger: "blur" }],
          unitPrice: [
            {
              required: true,
              validator: validateUnitPrice,
              msg: "请输入单价",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        editIndex: "",
        dialogVisible: false,
      };
      this.contractList = [];
      this.contractDialogVisible = false;
      this.contractTemplate = "";
      this.tenderDetail = null;
      this.paymentmethod.orderProcess = "";
      this.paymentmethod.payMode = "";
      this.paymentmethod.bankBillTerm = "";
    },
    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.publishForm.orderProcess == "KH") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "SK";
      }
      if (newVal == "QK") {
        this.publishForm.arbAdditionInfo.fqJhTermIndex = "";
        this.publishForm.payDeliverRateJhList = [];
        this.deliverSetList = false;
      }
    },

    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      // this.publishForm.payDeliverRateFkList = [];
      this.publishForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        // let obj = {
        //   termIndex: i + 1 + "",
        //   rate: "",
        //   date: "",
        //   payMode: "",

        //   type: "FK",
        // };

        // this.publishForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };

        this.publishForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.publishForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.publishForm.payDeliverRateFkList[val].termIndex = value;
    },
    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },

    getTime() {
      let date = new Date();

      let h = date.getHours(),
        m = date.getMonth(),
        s = date.getSeconds();

      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
        s < 10 ? "0" + s : s
      }`;
    },
    last() {
      if (this.examineShow == true) {
        this.$emit("lastShow");
      } else {
        this.$emit("last", JSON.parse(JSON.stringify(this.publishForm)));
      }
    },
    next() {
      let isEdit = this.$route.query.isEdit === "true";
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          //   if (
          //     formatNewDate(this.publishForm.startTime).getTime() <=
          //     new Date().getTime()
          //   ) {
          //     return this.$message.warning(
          //       "有效期开始时间的设置需要大于当前时间！"
          //     );
          //   }
          // if (formatNewDate(this.publishForm.endTime).getTime() >= formatNewDate(this.publishForm.deliveryDate).getTime()) {
          //   return this.$message.warning('交货时间的设置需要大于有效期的结束时间！');
          // }
          //   if (!this.publishForm.proList.length)
          //     return this.$message.info("请添加产品！");

          this.$confirm(
            `此操作将${!isEdit ? "发布" : "修改"}竞标信息, 是否继续?`,
            `立即${!isEdit ? "发布" : "修改"}`,
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            this.publishForm.bidPrice = this.bidTotalPrice;
            this.updateCompetitive();
            // if (isEdit) {
            //   this.updateCompetitive();
            // } else {
            //   this.addCompetitive();
            // }
          });
        }
      });
    },
    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },

    addProduct() {
      this.$refs.productForm.validate((valid) => {
        if (valid) {
          if (!this.productManage.form.unit) {
            return this.$message.warning("请选择单位");
          }
          if (
            this.productManage.form.number *
              this.productManage.form.unitPrice >=
            10000000000
          ) {
            return this.$message.warning("总价需小于 10000000000 元");
          }

          this.productManage.form.totalPrice =
            this.productManage.form.number * this.productManage.form.unitPrice;
          // -----------

          // -----------

          if (!this.productManage.isEdit) {
            this.publishForm.proList.push({ ...this.productManage.form });
          } else {
            this.publishForm.proList.splice(this.productManage.editIndex, 1, {
              ...this.productManage.form,
            });
          }

          this.productManage.dialogVisible = false;
        }
      });
    },
    deleteProduct(index) {
      this.publishForm.proList.splice(index, 1);
    },
    addCompetitive() {
      this.publishForm.payMode = this.publishForm.payMode
        .toString()
        .replaceAll(",", "|");
      // console.log('  this.publishForm.payMode ' , this.publishForm.payMode );

      this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
      console.log(
        "this.publishForm.arbAdditionInfo.payMode",
        this.publishForm.arbAdditionInfo.payMode
      );
      console.log(" this.publishForm.payMode", this.publishForm.payMode);

      if (this.publishForm.payMode.length !== 4) {
        this.publishForm.arbAdditionInfo.payPercent =
          this.publishForm.arbAdditionInfo.yhzzPercentage +
          "|" +
          this.publishForm.arbAdditionInfo.cdhpPercentage;
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHZZ") !== -1
      ) {
        this.publishForm.arbAdditionInfo.payPercent =
          this.publishForm.arbAdditionInfo.yhzzPercentage;
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.payPercent =
          this.publishForm.arbAdditionInfo.cdhpPercentage;
      }

      this.publishForm.arbAdditionInfo.bankBillTerm =
        this.publishForm.bankBillTerm;
      let publishForm = { ...this.publishForm };
      // publishForm.delRequire = this.addPrompt();
      publishForm.categoryId =
        publishForm.categoryId[publishForm.categoryId.length - 1];

      addCompetitive({
        ...publishForm,
      }).then((res) => {
        if (res.errno === 0) {
          this.$message({
            type: "success",
            message: "发布成功！",
          });
          this.$router.push({
            path: "/User/MyCompetitive",
          });
        }
      });
    },
    updateCompetitive() {
      this.publishForm.payMode = this.publishForm.payMode
        .toString()
        .replaceAll(",", "|");
      // console.log('  this.publishForm.payMode ' , this.publishForm.payMode );

      this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
      //百分比

      let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
      let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
      let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

      if (this.publishForm.payMode == [] || this.publishForm.payMode == "") {
        this.publishForm.arbAdditionInfo.payPercent = "";
      } else if (
        this.publishForm.payMode.length == 14 ||
        this.publishForm.payMode.length == 9
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        if (CDHPper == "") {
          CDHPper = "0";
        }
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + CDHPper + "|" + YHHPper;
        console.log("payPercent1");
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHZZ") !== -1
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + "0" + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        if (CDHPper == "") {
          CDHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + CDHPper + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHHP") !== -1
      ) {
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + "0" + "|" + YHHPper;
      }
      this.publishForm.arbAdditionInfo.bankBillTerm =
        this.publishForm.bankBillTerm;

      let publishForm = { ...this.publishForm };

      // publishForm.categoryId =
      //   publishForm.categoryId[publishForm.categoryId.length - 1];
      updateCompetitive({
        // id: this.$route.query.id,
        id: this.id,
        steps: "3",
        ...publishForm,
        categoryId: Array.isArray(this.publishForm.categoryId)
          ? this.publishForm.categoryId[this.publishForm.categoryId.length - 1]
          : this.publishForm.categoryId,
      }).then((res) => {
        if (res.errno === 0) {
          // this.$message({
          //   type: "success",
          //   message: "修改成功！",
          // });
          this.$router.push({
            path: "/User/MyCompetitive",
          });
        } else {
          //付款方式
          if (
            this.publishForm.arbAdditionInfo.payMode &&
            this.publishForm.payMode.indexOf("|") != -1
          ) {
            this.publishForm.payMode = this.publishForm.payMode.split("|");
          } else {
            if (this.publishForm.arbAdditionInfo.payMode == "") {
              this.publishForm.payMode = [];
            } else {
              this.publishForm.payMode = [this.publishForm.payMode];
            }
          }
          //百分比
          if (this.publishForm.arbAdditionInfo.payPercent !== "") {
            let percentageArr =
              this.publishForm.arbAdditionInfo.payPercent.split("|");

            this.$set(
              this.publishForm.arbAdditionInfo,
              "cdhpPercentage",
              percentageArr[1]
            );

            this.$set(
              this.publishForm.arbAdditionInfo,
              "yhzzPercentage",
              percentageArr[0]
            );
          } else {
            this.publishForm.arbAdditionInfo.cdhpPercentage = "";
            this.publishForm.arbAdditionInfo.yhzzPercentage = "";
          }
        }
      });
    },
    getBIContract() {
      getBIContract({
        page: 1,
        limit: 100,
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
    onValidityDateTimeChange(value) {
      this.publishForm.startTime = value[0];
      this.publishForm.endTime = value[1];
    },
    onBlurTaxRate(value) {
      if (isNaN(value)) return;
      this.publishForm.taxRate = Number(value).toFixed(2);
    },
    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.list[0].contractTextModel;
        }
      });
      this.contractDialogVisible = true;
    },
    showProductDialog(index, row) {
      this.productManage.form.unit = "";
      if (row) {
        this.productManage.isEdit = true;
        this.productManage.editIndex = index;
        Object.keys(row).forEach(
          (key) => (this.productManage.form[key] = row[key])
        );
      } else {
        this.productManage.isEdit = false;
      }

      this.productManage.dialogVisible = true;
    },
    validateStartTime(rule, value, callback) {
      if (!value) {
        return callback(new Error("请选择有效期"));
      } else if (formatNewDate(value[0]).getTime() < Date.now()) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else if (
        formatNewDate(value[1]).getTime() - formatNewDate(value[0]).getTime() >
        1000 * 60 * 60 * 24 * 30
      ) {
        return callback(new Error("有效期时间不能大于 30 天"));
      } else {
        callback();
      }
    },
    //  表格上传
    beforeAvatarUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      //
      if (!isXlsx) {
        this.$message.error("导入表格文件只能是 .xlsx格式!");
      }
      return isXlsx;
    },
    handleAvatarSuccess(res, file) {
      if (res.errno === 0) {
        this.publishForm.proList = res.data;
        // this.publishForm.proList.map(item=>{
        //   item.totalPrice
        // })
      } else {
        this.$message.error(res.errmsg);
      }
    },
    // 根据选择添加付款方式和提示进合同
    // addPrompt() {
    //   let prompt = this.publishForm;
    //   if (prompt.orderProcess == 'HK') {
    //     if (prompt.payMode == 'YHZZ') {
    //       return `<p>付款方式：银行转账</p>` + prompt.delRequire;
    //     } else {
    //       return `<p>付款方式：商业承兑汇票，承兑期限为${prompt.bankBillTerm}个月</p>` + prompt.delRequire;
    //     }
    //   } else {
    //     if (prompt.payMode == 'YHZZ') {
    //       return `<p>付款方式：银行转账，签订合同后3天以内以银行转账的方式一次性付清</p>` + prompt.delRequire;
    //     } else {
    //       return `<p>付款方式：商业承兑汇票，在签订合同后3天以内以承兑期限为${prompt.bankBillTerm}个月的商业承兑汇票一次性付清</p>` + prompt.delRequire;
    //     }
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row current-row {
  height: 10px;
}

/deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  text-align: center;
}
.addPayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin: 0 0 30px 360px;
}
.reducePayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
}
.publish-container {
  background: #fff;
  padding-bottom: 20px;
  .form-content {
    padding-top: 20px;
    margin: 0 auto;
    width: 90%;
    margin-bottom: 30px;
    position: relative;
    .btnBoxStyle {
      .btnLast {
        position: absolute;
        right: 53%;
        z-index: 15;
      }
      .btnNext {
        width: 85px;
        position: absolute;
        right: 45%;
        z-index: 15;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    // el-date-picker el-popper has-time
    .input-width {
      width: 300px;
      font-size: 12px;
    }
    .input-width-small {
      width: 60px;
    }
    .table-input-width {
      width: 250px;
      margin-top: 20px;
    }
    .capital {
      margin-top: 15px;
    }
    .form-item {
      margin-right: 0;
      margin-bottom: 30px;
      width: 50%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .form-item-invsStatus {
      margin-right: -150px;
      // margin-left: 70px;
      margin-bottom: 30px;
      width: 38%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .form-item-deliverSetDay {
      margin-right: -80px;
      // margin-left: 70px;
      margin-bottom: 30px;
      width: 38%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .avatar {
      width: 148px;
      height: 148px;
      display: block;
    }
    .select-style {
      width: 300px;
      .el-select-dropdown__item {
        height: auto;
      }
    }
  }
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      top: -8px;
      left: 0;
    }
    .num {
      bottom: -8px;
      left: 0;
    }
  }
}
.product-content {
  margin-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .upbtn {
      display: flex;

      .upload-demo {
        margin: 0px 8px;
      }
    }
  }
}
.product-list {
  .input-width {
    width: 280px;
  }
}
/deep/.el-form-item__label {
  font-size: 12px;
  padding: 0;
}

/deep/.mce-tinymce {
  box-shadow: none;
}
</style>
