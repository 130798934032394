<template>
  <div class="nav-wrap">
    <div v-for="(items, index) in nav" :key="index" class="nav-module">
      <i
        :class="[show[index] ? 'el-icon-minus' : 'el-icon-plus']"
        style="position: absolute; top: 5px; right: 0; cursor: pointer"
        @click="open(index)"
      ></i>
      <div class="nav-title">{{ items.name }}</div>
      <div v-show="show[index]">
        <div
          v-for="(item, indexs) in items.child"
          @click="routerTo(item)"
          :key="indexs"
        >
          <div class="nav-box" :class="{ selected: selected == item.path }">
            <div
              class="mark"
              :style="{
                background: selected == item.path ? '#409eff' : '#666',
              }"
            ></div>
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let _this = this;
    this.selected = this.$route.path;

    this.initMenu(this.accountType);

    let FirstLv = [];
    if (this.$route.meta) {
      if (this.$route.meta.FirstLv) {
        for (var i in _this.nav) {
          if (_this.nav[i].name == this.$route.meta.FirstLv) {
            this.$set(this.show, i, true);
          }
        }
      }
    }
  },
  data() {
    return {
      show: [false, false, false, false, false, false],
      nav: [
        {
          name: "基础信息",
          child: [
            {
              name: "企业信息",
              Logo: "corporateInformation",
              path: "/User/CorporateInfo",
            },
          ],
        },
        {
          name: "竞卖管理",
          child: [
            {
              name: "发布竞卖",
              Logo: "post-auction",
              path: "/User/PublishBidding",
            },
            {
              name: "我的竞卖",
              Logo: "my-auction",
              path: "/User/MyBidding",
            },
            {
              name: "参与的竞卖",
              Logo: "participating",
              path: "/User/MyPartBidding",
            },
          ],
        },
        {
          name: "竞买管理",
          child: [
            {
              name: "发布竞买",
              Logo: "post-bid",
              path: "/User/Publish",
            },
            {
              name: "我的竞买",
              Logo: "MyBid-mai",
              path: "/User/MyPublish",
            },
            {
              name: "参与的竞买",
              Logo: "participating-bid",
              path: "/User/MyPartPublish",
            },
          ],
        },
        {
          name: "竞标管理",
          child: [
            {
              name: "发布竞标",
              Logo: "post-bid",
              path: "/User/PublishCompetitive",
            },
            {
              name: "我的竞标",
              Logo: "MyBid-biao",
              path: "/User/MyCompetitive",
            },
          ],
        },
        {
          name: "店铺管理",
          child: [
            {
              name: "店铺信息",
              Logo: "store-information",
              path: "/User/StoreInfo",
            },
            {
              name: "添加产品",
              Logo: "add-product",
              path: "/User/AddProduct",
            },
            {
              name: "产品列表",
              Logo: "product-list",
              path: "/User/ProductList",
            },
          ],
        },
        {
          name: "订单管理",
          child: [
            {
              name: "普通订单",
              Logo: "normal-order",
              path: "/User/MyOrder",
            },
            {
              name: "竞买/竞卖订单",
              Logo: "auction-order",
              path: "/User/ReqOrder",
            },
            {
              name: "竞标订单",
              Logo: "bidding-order",
              path: "/User/BidOrder",
            },
          ],
        },
      ],
      selected: "",
    };
  },
  watch: {
    $route: function (newV, oldV) {
      this.selected = this.$route.path;
    },
    accountType: function (newV, oldV) {
      this.initMenu(this.accountType);
    },
  },

  computed: {
    accountType() {
      return this.$store.getters["getterMenuType"];
    },
  },

  methods: {
    routerTo(data) {
      console.log(data);
      this.$router.push({ path: data.path, query: { name: data.name } });
    },
    initMenu(accountType) {
      let _this = this;
      if (accountType != null) {
        if (accountType == "BUY") {
          _this.nav = undefined;
          _this.nav = new Array();
          _this.nav = [
            {
              name: "基础信息",
              child: [
                {
                  name: "企业信息",
                  Logo: "corporateInformation",
                  path: "/User/CorporateInfo",
                },
              ],
            },
            {
              name: "竞卖管理",
              child: [
                {
                  name: "参与的竞卖",
                  Logo: "participating",
                  path: "/User/MyPartBidding",
                },
              ],
            },
            {
              name: "竞买管理",
              child: [
                {
                  name: "发布竞买",
                  Logo: "post-bid",
                  path: "/User/Publish",
                },
                {
                  name: "我的竞买",
                  Logo: "MyBid-mai",
                  path: "/User/MyPublish",
                },
              ],
            },
            {
              name: "竞标管理",
              child: [
                {
                  name: "发布竞标",
                  Logo: "post-bid",
                  path: "/User/PublishCompetitive",
                },
                {
                  name: "我的竞标",
                  Logo: "MyBid-biao",
                  path: "/User/MyCompetitive",
                },
              ],
            },
            {
              name: "订单管理",
              child: [
                {
                  name: "普通订单",
                  Logo: "normal-order",
                  path: "/User/MyOrder?customer=buyder",
                },
                {
                  name: "竞买/竞卖订单",
                  Logo: "auction-order",
                  path: "/User/ReqOrder?customer=buyder",
                },
                {
                  name: "竞标订单",
                  Logo: "bidding-order",
                  path: "/User/BidOrder?customer=buyder",
                },
              ],
            },
            {
              name: "合同管理",
              child: [
                {
                  name: "我的合同",
                  Logo: "my-contract",
                  path: "/User/MyContract?customer=buyder",
                },
              ],
            },
          ];
        } else {
          _this.nav = new Array();
          _this.nav = [
            {
              name: "基础信息",
              child: [
                {
                  name: "企业信息",
                  Logo: "corporateInformation",
                  path: "/User/CorporateInfo",
                },
              ],
            },
            {
              name: "竞卖管理",
              child: [
                {
                  name: "发布竞卖",
                  Logo: "post-auction",
                  path: "/User/PublishBidding",
                },
                {
                  name: "我的竞卖",
                  Logo: "my-auction",
                  path: "/User/MyBidding",
                },
              ],
            },
            {
              name: "竞买管理",
              child: [
                {
                  name: "参与的竞买",
                  Logo: "participating-bid",
                  path: "/User/MyPartPublish",
                },
              ],
            },
            {
              name: "竞标管理",
              child: [
                {
                  name: "参与的竞标",
                  Logo: "participated-in-bidding",
                  path: "/User/MyPartCompetitive",
                },
              ],
            },
            {
              name: "店铺管理",
              child: [
                {
                  name: "店铺信息",
                  Logo: "store-information",
                  path: "/User/StoreInfo",
                },
                {
                  name: "添加产品",
                  Logo: "add-product",
                  path: "/User/AddProduct",
                },
                {
                  name: "产品列表",
                  Logo: "product-list",
                  path: "/User/ProductList",
                },
              ],
            },
            {
              name: "订单管理",
              child: [
                {
                  name: "普通订单",
                  Logo: "normal-order",
                  path: "/User/MyOrder?customer=sellder",
                },
                {
                  name: "竞买/竞卖订单",
                  Logo: "auction-order",
                  path: "/User/ReqOrder?customer=sellder",
                },
                {
                  name: "竞标订单",
                  Logo: "bidding-order",
                  path: "/User/BidOrder?customer=sellder",
                },
              ],
            },
            {
              name: "合同管理",
              child: [
                {
                  name: "我的合同",
                  Logo: "my-contract",
                  path: "/User/SellMyContract?customer=sellder",
                },
              ],
            },
          ];
        }

        var parentId = localStorage.getItem("parentId");
        var enterpriseId = localStorage.getItem("enterpriseId");
        if (parentId == 0) {
          //父帐号
          _this.nav[0].child.push({
            name: "认证信息",
            path: "/User/AttestInfo",
          });
          if (enterpriseId == 0) {
          } else {
            _this.nav[0].child.push({
              name: "账户管理",
              path: "/User/SubAccount",
            });
          }
        }

        _this.nav[0].child.push({
          name: "收货地址",
          path: "/User/Address",
        });
      }
    },

    open(index) {
      let value = !this.show[index];
      this.$set(this.show, index, value);
    },
  },
};
</script>

<style lang="less" scoped>
.nav-wrap {
  padding: 24px 24px 0;
  background: #fff;
  width: 172px;
  box-sizing: border-box;

  .nav-module {
    margin-bottom: 40px;
    position: relative;

    .nav-title {
      // font-weight: bold;
      margin-bottom: 30px;
      font-size: 14px;
    }

    .nav-box {
      margin: 0 0 29px 20px;
      cursor: pointer;
      font-weight: 500;
      color: #666;
      font-size: 14px;
      position: relative;
    }
  }
}

.selected {
  color: #409eff !important;
}

.mark {
  position: absolute;
  width: 4px;
  height: 4px;
  top: 8px;
  left: -10px;
}
</style>
