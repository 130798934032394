var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "publish-container" },
    [
      _c(
        "div",
        { staticClass: "form-content" },
        [
          _vm.examineShow
            ? _c("div", { staticClass: "transparentBox" })
            : _vm._e(),
          _c("div", { staticClass: "product-content" }, [
            _c("div", { staticClass: "header" }, [
              _c("h3", [_vm._v("产品列表")]),
              _c(
                "div",
                { staticClass: "upbtn" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.showProductDialog },
                    },
                    [_vm._v("添加产品")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.importExcel,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-success": _vm.handleAvatarSuccess,
                        "show-file-list": false,
                        multiple: false,
                        accept: ".xlsx",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("导入产品")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-link",
                    {
                      staticStyle: { "font-size": "10px" },
                      attrs: {
                        href: "excel/竞标商品导入模板.xlsx",
                        title: "竞标商品导入模板",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [
                          _c("i", {
                            staticClass: "el-icon-download el-icon--left",
                          }),
                          _vm._v("商品导入模板"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.publishForm.proList,
                      "header-cell-style": {
                        background: "#f5f5f5",
                        "font-weight": 500,
                        color: "#666",
                        padding: "12 5px",
                      },
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productBrand",
                        label: "产品品牌",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "productType", label: "产品型号" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "specification", label: "产品规格" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "number",
                        align: "center",
                        label: "采购量",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.number) +
                                  " " +
                                  _vm._s(scope.row.unit) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unitPrice",
                        align: "center",
                        label: "单价(元)",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "totalPrice",
                        align: "center",
                        label: "总价(元)",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { fixed: "right", align: "center", label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showProductDialog(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteProduct(scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                padding: "0 20px 30px",
                "text-align": "right",
                "font-size": "16px",
              },
            },
            [
              _c("span", [_vm._v("标的价格：")]),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "#fc4500",
                    "font-size": "22px",
                    "margin-left": "10px",
                  },
                },
                [_vm._v(_vm._s(_vm.bidTotalPrice))]
              ),
              _vm._v(" 元 "),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "productManage",
              attrs: {
                inline: "",
                model: _vm.paymentmethod,
                rules: _vm.publishFormRules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "补充说明：", prop: "supplement" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.paymentmethod.supplement,
                            callback: function ($$v) {
                              _vm.$set(_vm.paymentmethod, "supplement", $$v)
                            },
                            expression: "paymentmethod.supplement",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { label: "BJSM" },
                              model: {
                                value: _vm.paymentmethod.supplement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paymentmethod, "supplement", $$v)
                                },
                                expression: "paymentmethod.supplement",
                              },
                            },
                            [_vm._v("报价说明")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { label: "DKJS" },
                              model: {
                                value: _vm.paymentmethod.supplement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paymentmethod, "supplement", $$v)
                                },
                                expression: "paymentmethod.supplement",
                              },
                            },
                            [_vm._v("贷款结算支付方式")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { label: "JJFS" },
                              model: {
                                value: _vm.paymentmethod.supplement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paymentmethod, "supplement", $$v)
                                },
                                expression: "paymentmethod.supplement",
                              },
                            },
                            [_vm._v("计价方式")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { label: "ZLJS" },
                              model: {
                                value: _vm.paymentmethod.supplement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paymentmethod, "supplement", $$v)
                                },
                                expression: "paymentmethod.supplement",
                              },
                            },
                            [_vm._v("质量技术标准")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "publishForm",
              attrs: {
                inline: "",
                model: _vm.publishForm,
                rules: _vm.publishFormRules,
                "label-width": "130px",
              },
            },
            [
              _vm.paymentmethod.supplement.indexOf("BJSM") !== -1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "报价说明：", prop: "bidRequire" } },
                    [
                      _c("tinymce-editor", {
                        staticStyle: { width: "672px" },
                        attrs: { value: _vm.publishForm.bidRequire },
                        on: {
                          input: function ($event) {
                            _vm.publishForm.bidRequire = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paymentmethod.supplement.indexOf("DKJS") !== -1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "贷款结算支付方式：",
                        prop: "delRequire",
                      },
                    },
                    [
                      _c("tinymce-editor", {
                        staticStyle: { width: "672px" },
                        attrs: { value: _vm.publishForm.delRequire },
                        on: {
                          input: function ($event) {
                            _vm.publishForm.delRequire = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paymentmethod.supplement.indexOf("JJFS") !== -1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "计价方式：", prop: "pricRequire" } },
                    [
                      _c("tinymce-editor", {
                        staticStyle: { width: "672px" },
                        attrs: { value: _vm.publishForm.pricRequire },
                        on: {
                          input: function ($event) {
                            _vm.publishForm.pricRequire = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.paymentmethod.supplement.indexOf("ZLJS") !== -1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "质量技术标准：", prop: "qualRequire" } },
                    [
                      _c("tinymce-editor", {
                        staticStyle: { width: "672px" },
                        attrs: { value: _vm.publishForm.qualRequire },
                        on: {
                          input: function ($event) {
                            _vm.publishForm.qualRequire = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "btnBoxStyle" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnLast",
                      attrs: { type: "primary" },
                      on: { click: _vm.last },
                    },
                    [_vm._v("上一步")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btnNext",
                      attrs: { type: "primary" },
                      on: { click: _vm.next },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.productManage.isEdit ? "编辑产品" : "添加产品",
            visible: _vm.productManage.dialogVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.productManage, "dialogVisible", $event)
            },
            closed: function ($event) {
              return _vm.$refs.productForm.resetFields()
            },
          },
        },
        [
          _c("div", { staticClass: "product-list" }, [
            _c(
              "div",
              { staticClass: "product-item" },
              [
                _c(
                  "el-form",
                  {
                    ref: "productForm",
                    attrs: {
                      inline: "",
                      model: _vm.productManage.form,
                      rules: _vm.productManage.formRule,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "产品名称：", prop: "productName" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "请输入产品名称",
                            size: "small",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.productManage.form.productName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.productManage.form,
                                "productName",
                                $$v
                              )
                            },
                            expression: "productManage.form.productName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "产品品牌：", prop: "productBrand" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "请输入产品品牌",
                            size: "small",
                            maxlength: "30",
                          },
                          model: {
                            value: _vm.productManage.form.productBrand,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.productManage.form,
                                "productBrand",
                                $$v
                              )
                            },
                            expression: "productManage.form.productBrand",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "产品型号：", prop: "productType" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "请输入产品型号",
                            size: "small",
                            maxlength: "20",
                          },
                          model: {
                            value: _vm.productManage.form.productType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.productManage.form,
                                "productType",
                                $$v
                              )
                            },
                            expression: "productManage.form.productType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "产品规格：", prop: "specification" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "请输入产品规格",
                            size: "small",
                            maxlength: "20",
                          },
                          model: {
                            value: _vm.productManage.form.specification,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.productManage.form,
                                "specification",
                                $$v
                              )
                            },
                            expression: "productManage.form.specification",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "采购量：", prop: "number" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          staticStyle: {
                            width: "172px",
                            "margin-right": "8px",
                          },
                          attrs: {
                            maxlength: "10",
                            placeholder: "请输入需求量",
                            size: "small",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.blurmethod($event, "number")
                            },
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.keyup($event, "number")
                            },
                          },
                          model: {
                            value: _vm.productManage.form.number,
                            callback: function ($$v) {
                              _vm.$set(_vm.productManage.form, "number", $$v)
                            },
                            expression: "productManage.form.number",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            attrs: {
                              placeholder: "选择单位",
                              "allow-create": "",
                              filterable: "",
                              "default-first-option": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.productManage.form.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.productManage.form, "unit", $$v)
                              },
                              expression: "productManage.form.unit",
                            },
                          },
                          _vm._l(_vm.unitList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "单价(元)：", prop: "unitPrice" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            maxlength: "13",
                            placeholder: "请输入单价",
                            size: "small",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.blurmethod($event, "unitPrice")
                            },
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.keyup($event, "unitPrice")
                            },
                          },
                          model: {
                            value: _vm.productManage.form.unitPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.productManage.form, "unitPrice", $$v)
                            },
                            expression: "productManage.form.unitPrice",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: { label: "总价(元)：" },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (
                                    _vm.productManage.form.number *
                                    _vm.productManage.form.unitPrice
                                  ).toFixed(2) || 0
                                )
                              ),
                            ]),
                            _vm.productManage.form.number *
                              _vm.productManage.form.unitPrice >
                            10000000000
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(" （总价不超过 9999999999.99 元）"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "要求及说明：", prop: "require" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "646px" },
                          attrs: {
                            type: "textarea",
                            maxlength: "100",
                            resize: "none",
                            rows: "5",
                            placeholder: "请输入要求及说明：",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.productManage.form.require,
                            callback: function ($$v) {
                              _vm.$set(_vm.productManage.form, "require", $$v)
                            },
                            expression: "productManage.form.require",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.productManage.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addProduct } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }