var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        margin: "0 auto",
        width: "1200px",
        display: "flex",
        padding: "10px 0",
      },
    },
    [
      _c("Nav", { staticStyle: { "margin-right": "10px" } }),
      _c("router-view", { staticStyle: { flex: "1", "overflow-x": "hidden" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }