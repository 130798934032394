<template>
  <div style="margin:0 auto;width:1200px;display:flex;padding:10px 0">
    <Nav style="margin-right:10px"></Nav>
    <router-view style="flex:1;overflow-x: hidden;" />
  </div>
</template>

<script>
import Nav from './component/Nav';
export default {
  components: {
    Nav
  }
};
</script>

<style></style>
