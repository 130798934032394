<template>
  <div class="publish-container">
    <!-- <el-alert
      v-if="tenderDetail && tenderDetail.apprvStatus === 'F'"
      :title="tenderDetail.apprvRemark"
      type="error"
      show-icon
    >
    </el-alert> -->
    <div class="form-content">
      <!-- 遮罩 -->

      <div class="transparentBox" v-if="examineShow"></div>

      <div class="product-content">
        <div class="header">
          <!-- {{ publishForm }} -->
          <!-- bidId  \\\\\{{ bidId }}
            isbidId
        {{ isbidId}}
        thisid {{this.id}} -->
          <h3>产品列表</h3>
          <div class="upbtn">
            <el-button
              type="primary"
              @click="showProductDialog"
              size="small"
              style="float: right"
              >添加产品</el-button
            >
            <el-upload
              class="upload-demo"
              :action="importExcel"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :show-file-list="false"
              :multiple="false"
              accept=".xlsx"
            >
              <el-button size="small" type="primary">导入产品</el-button>
            </el-upload>
            <!--<a href="excel/竞标商品导入模板.xlsx">商品导入模板</a>-->
            <el-link
              href="excel/竞标商品导入模板.xlsx"
              title="竞标商品导入模板"
              style="font-size: 10px"
              ><el-button size="small" type="primary"
                ><i class="el-icon-download el-icon--left"></i
                >商品导入模板</el-button
              ></el-link
            >
          </div>
        </div>
        <div class="table">
          <el-table
            :data="publishForm.proList"
            :header-cell-style="{
              background: '#f5f5f5',
              'font-weight': 500,
              color: '#666',
              padding: '12 5px',
            }"
            border
            style="width: 100%"
          >
            <el-table-column prop="productName" label="产品名称" width="180">
            </el-table-column>
            <el-table-column prop="productBrand" label="产品品牌" width="180">
            </el-table-column>
            <el-table-column prop="productType" label="产品型号">
            </el-table-column>
            <el-table-column prop="specification" label="产品规格">
            </el-table-column>
            <el-table-column prop="number" align="center" label="采购量">
              <template slot-scope="scope">
                {{ scope.row.number }} {{ scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column prop="unitPrice" align="center" label="单价(元)">
            </el-table-column>
            <el-table-column prop="totalPrice" align="center" label="总价(元)">
            </el-table-column>
            <el-table-column fixed="right" align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="showProductDialog(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  style="color: red"
                  @click="deleteProduct(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="padding: 0 20px 30px; text-align: right; font-size: 16px">
        <span>标的价格：</span>
        <span style="color: #fc4500; font-size: 22px; margin-left: 10px">{{
          bidTotalPrice
        }}</span>
        元
      </div>
      <el-form
        inline
        :model="paymentmethod"
        :rules="publishFormRules"
        ref="productManage"
        label-width="130px"
      >
        <div>
          <el-form-item class="form-item" label="补充说明：" prop="supplement">
            <el-checkbox-group v-model="paymentmethod.supplement">
              <el-checkbox v-model="paymentmethod.supplement" label="BJSM"
                >报价说明</el-checkbox
              >
              <el-checkbox v-model="paymentmethod.supplement" label="DKJS"
                >贷款结算支付方式</el-checkbox
              >
              <el-checkbox v-model="paymentmethod.supplement" label="JJFS"
                >计价方式</el-checkbox
              >
              <el-checkbox v-model="paymentmethod.supplement" label="ZLJS"
                >质量技术标准</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>

      <el-form
        inline
        :model="publishForm"
        :rules="publishFormRules"
        ref="publishForm"
        label-width="130px"
      >
        <!-- ——--———————————————————————————————————————————————————————————————————— -->
        <el-form-item
          label="报价说明："
          prop="bidRequire"
          v-if="paymentmethod.supplement.indexOf('BJSM') !== -1"
        >
          <tinymce-editor
            :value="publishForm.bidRequire"
            @input="publishForm.bidRequire = $event"
            style="width: 672px"
          ></tinymce-editor>
        </el-form-item>
        <el-form-item
          label="贷款结算支付方式："
          prop="delRequire"
          v-if="paymentmethod.supplement.indexOf('DKJS') !== -1"
        >
          <tinymce-editor
            :value="publishForm.delRequire"
            @input="publishForm.delRequire = $event"
            style="width: 672px"
          ></tinymce-editor>
        </el-form-item>
        <el-form-item
          label="计价方式："
          prop="pricRequire"
          v-if="paymentmethod.supplement.indexOf('JJFS') !== -1"
        >
          <tinymce-editor
            :value="publishForm.pricRequire"
            @input="publishForm.pricRequire = $event"
            style="width: 672px"
          ></tinymce-editor>
        </el-form-item>
        <el-form-item
          label="质量技术标准："
          prop="qualRequire"
          v-if="paymentmethod.supplement.indexOf('ZLJS') !== -1"
        >
          <tinymce-editor
            :value="publishForm.qualRequire"
            @input="publishForm.qualRequire = $event"
            style="width: 672px"
          ></tinymce-editor>
        </el-form-item>

        <div class="btnBoxStyle">
          <!-- <el-button type="primary" @click="onPulish">{{
            $route.query.isEdit === "true" ? "修改" : "立即发布"
          }}</el-button> -->
          <el-button class="btnLast" type="primary" @click="last"
            >上一步</el-button
          >

          <el-button class="btnNext" type="primary" @click="next"
            >下一步</el-button
          >
        </div>
      </el-form>
    </div>

    <!-- 添加和编辑产品 -->
    <el-dialog
      :title="productManage.isEdit ? '编辑产品' : '添加产品'"
      :visible.sync="productManage.dialogVisible"
      width="800px"
      @closed="$refs.productForm.resetFields()"
    >
      <div class="product-list">
        <div class="product-item">
          <el-form
            inline
            :model="productManage.form"
            :rules="productManage.formRule"
            ref="productForm"
            label-width="80px"
          >
            <el-form-item
              class="form-item"
              label="产品名称："
              prop="productName"
            >
              <el-input
                class="input-width"
                v-model="productManage.form.productName"
                placeholder="请输入产品名称"
                size="small"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="产品品牌："
              prop="productBrand"
            >
              <el-input
                class="input-width"
                v-model="productManage.form.productBrand"
                placeholder="请输入产品品牌"
                size="small"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="产品型号："
              prop="productType"
            >
              <el-input
                class="input-width"
                v-model="productManage.form.productType"
                placeholder="请输入产品型号"
                size="small"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="form-item"
              label="产品规格："
              prop="specification"
            >
              <el-input
                class="input-width"
                v-model="productManage.form.specification"
                placeholder="请输入产品规格"
                size="small"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item" label="采购量：" prop="number">
              <el-input
                class="input-width"
                v-model="productManage.form.number"
                maxlength="10"
                placeholder="请输入需求量"
                style="width: 172px; margin-right: 8px"
                size="small"
                @input.native="keyup($event, 'number')"
                @blur="blurmethod($event, 'number')"
              ></el-input>
              <el-select
                v-model="productManage.form.unit"
                placeholder="选择单位"
                style="width: 100px"
                allow-create
                filterable
                default-first-option
                size="small"
              >
                <el-option
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <!-- <el-input v-model="productManage.form.unit"
                        placeholder="请输入单位"
                        style="width:100px;"
                        size='small'></el-input> -->
            </el-form-item>
            <el-form-item class="form-item" label="单价(元)：" prop="unitPrice">
              <el-input
                class="input-width"
                v-model="productManage.form.unitPrice"
                maxlength="13"
                placeholder="请输入单价"
                size="small"
                @input.native="keyup($event, 'unitPrice')"
                @blur="blurmethod($event, 'unitPrice')"
              ></el-input>
            </el-form-item>
            <div class="form-item">
              <el-form-item class="form-item" label="总价(元)：">
                <span>{{
                  (
                    productManage.form.number * productManage.form.unitPrice
                  ).toFixed(2) || 0
                }}</span>
                <span
                  v-if="
                    productManage.form.number * productManage.form.unitPrice >
                    10000000000
                  "
                  style="color: red"
                >
                  （总价不超过 9999999999.99 元）</span
                >
              </el-form-item>
            </div>
            <el-form-item class="form-item" label="要求及说明：" prop="require">
              <el-input
                v-model="productManage.form.require"
                type="textarea"
                maxlength="100"
                resize="none"
                rows="5"
                style="width: 646px"
                placeholder="请输入要求及说明："
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productManage.dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="addProduct">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBIContract,
  categoryAll,
  addCompetitive,
  updateCompetitive,
  getSelectBiddingDetails,
  importExcel,
  getContractInfo,
} from "../../../../api";
import { formatNewDate } from "@/utils/util";
import tinymceEditor from "@/components/tinymceEditor.vue";
import Title from "../../../../component/Title";
import { regRule } from "@/utils/validate";
import indexVue from "../../../../index.vue";

export default {
  components: {
    Title,
    tinymceEditor,
  },
  props: {
    publishForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bidId: {
      type: String,
      default: () => {
        return {};
      },
    },
    examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // $route: function (to) {
    //   console.log(to);
    //   if (Object.keys(to.query).length == 0) {
    //     this.resetData();
    //   }
    // },

    bidId: {
      handler(val) {
        this.id = val;
        console.log("idd", this.id);
      },
      immediate: true,
    },
    "publishForm.bidRequire": {
      handler(newVal, oldVal) {
        console.log(newVal + "---bidRequire---" + oldVal);
        if (newVal == "" && oldVal == "") {
          this.paymentmethod.supplement.splice(
            this.paymentmethod.supplement.indexOf("BJSM"),
            1
          );
        }
        if (newVal !== "" && newVal !== undefined) {
          console.log(1);
          this.paymentmethod.supplement.push("BJSM");
        }
      },
      immediate: true,
    },

    "publishForm.delRequire": {
      handler(newVal, oldVal) {
        if (newVal == "" && oldVal == "") {
          this.paymentmethod.supplement.splice(
            this.paymentmethod.supplement.indexOf("DKJS"),
            1
          );
        }
        if (newVal !== "" && newVal !== undefined) {
          console.log(1);
          this.paymentmethod.supplement.push("DKJS");
        }
      },
      immediate: true,
    },
    "publishForm.pricRequire": {
      handler(newVal, oldVal) {
        if (newVal == "" && oldVal == "") {
          this.paymentmethod.supplement.splice(
            this.paymentmethod.supplement.indexOf("JJFS"),
            1
          );
        }
        if (newVal !== "" && newVal !== undefined) {
          console.log(1);
          this.paymentmethod.supplement.push("JJFS");
        }
      },
      immediate: true,
    },
    "publishForm.qualRequire": {
      handler(newVal, oldVal) {
        if (newVal == "" && oldVal == "") {
          this.paymentmethod.supplement.splice(
            this.paymentmethod.supplement.indexOf("ZLJS"),
            1
          );
        }
        if (newVal !== "" && newVal !== undefined) {
          console.log(1);
          this.paymentmethod.supplement.push("ZLJS");
        }
      },
      immediate: true,
    },
    "paymentmethod.supplement": function (newVal, oldVal) {
      console.log(this.paymentmethod.supplement);
      //  console.log(this.publishForm.supplement.indexOf(''));
      //  　this.paymentmethod.supplement.splice(this.paymentmethod.supplement.indexOf('BJSM'),1)
      if (newVal.indexOf("BJSM") == -1) {
        this.publishForm.bidRequire = "";
      }
      if (newVal.indexOf("DKJS") == -1) {
        this.publishForm.delRequire = "";
      }
      if (newVal.indexOf("JJFS") == -1) {
        this.publishForm.pricRequire = "";
      }
      if (newVal.indexOf("ZLJS") == -1) {
        this.publishForm.qualRequire = "";
      }
    },

    "publishForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.publishForm.arbAdditionInfo.invsStatus = "KH";
      }
    },

    "publishForm.arbAdditionInfo.paySet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.paySetPort = "";
      }
      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.paySetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverSet": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "FQ") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "";
      }
      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.publishForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.deliverSetList = true;
      }
    },

    "publishForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);
      if (newVal == "SH" && oldVal == "ZT") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
      if (newVal == "ZT" && oldVal == "SH") {
        this.publishForm.arbAdditionInfo.deliverModeDay = "";
      }
    },
    "publishForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
      if (newVal == "GZB" && oldVal == "ORTHER") {
        this.publishForm.arbAdditionInfo.qualityTerm = "";
      }
    },
    "publishForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      if (newVal == "KQ" && oldVal == "KH") {
        this.publishForm.arbAdditionInfo.invsDay = "";
      }
    },
    "publishForm.payMode": function (newVal, oldVal) {
      console.log(newVal + "--" + oldVal);

      if (
        // this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        // this.publishForm.payMode.indexOf("CDHP") == -1
        this.publishForm.payMode.length == 1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        console.log("长度是1");
        this.publishForm.arbAdditionInfo.yhzzPercentage = "100";
        this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      }
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.length == 1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage = "100";
        this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      }
    },
  },
  data() {
    // let validatePrice = (rule, value, callback) => {
    //   if (!value && value !== 0) {
    //     return callback(new Error(rule.msg));
    //   } else if (!regRule.number1.test(value)) {
    //     return callback(new Error("请输入正确的报价"));
    //   } else if (value >= 100000000) {
    //     return callback();
    //   } else {
    //     callback();
    //   }
    // };
    let validateUnitPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请输入单价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("请输入正确的报价"));
      } else if (value >= 10000000000) {
        return callback(new Error("价格调整幅度需小于 10000000000 元"));
      } else {
        callback();
      }
    };
    let validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else {
        callback();
      }
    };
    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var validateStartTime = (rule, value, callback) => {
      console.log(new Date(value).getTime());
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value).getTime() < Date.now() - 1000) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else if (value.indexOf(".") !== -1) {
        return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
      } else if (value.length !== 1 && value[0] == 0) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else {
        callback();
      }
    };
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入采购量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 6
      ) {
        return callback(new Error("最多保留六位小数"));
      } else {
        callback();
      }
    };

    var validatePayAfterData = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 190之间日期"));
      } else {
        callback();
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.publishForm.payMode.indexOf("CDHP"));
      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.publishForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.publishForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.publishForm.payMode.length == 1 &&
        this.publishForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.publishForm.payMode.indexOf("YHZZ") !== -1 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.publishForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.publishForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.publishForm.payMode.indexOf("YHZZ") == -1 &&
        this.publishForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.publishForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }
      this.publishForm.payDeliverRateJhList;

      let newList = [];

      this.publishForm.payDeliverRateJhList.map((item) => {
        newList.push(item.termIndex);
      });

      for (var i = 0; i < newList.length; i++) {
        if (newList.indexOf(newList[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validatefqTermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };
    var validateProductName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入产品名称"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    var validateProductBrand = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入产品品牌"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    var validateProductType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入产品型号"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    var validateSpecification = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入产品规格"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };

    return {
      deliverSetList: false,
      paySetList: false,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      pickerStartOptions: {
        disabledDate: (time) => {
          return (
            time.getTime() < Date.now() - 1000 * 60 * 60 * 24 ||
            time.getTime() - Date.now() > 1000 * 60 * 60 * 24 * 31
          );
        },
      },
      deliveryPickerStartOptions: {
        disabledDate: (time) => {
          if (this.publishForm.dateTime) {
            let date = formatNewDate(this.publishForm.dateTime[1]);
            return (
              time.getTime() <
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).getTime()
            );
          } else {
            let date = new Date();
            return (
              time.getTime() <
              new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              ).getTime()
            );
          }
        },
      },
      //   publishForm: {
      //     bidName: "",
      //     categoryId: "",
      //     bidPrice: 0,
      //     startTime: "",
      //     endTime: "",
      //     dateTime: "",
      //     contacts: "",
      //     phone: "",
      //     contractInfo: "",
      //     deliveryAddress: "",
      //     bidRequire: "",
      //     delRequire: "",
      //     pricRequire: "",
      //     qualRequire: "",
      //     taxRate: "",
      //     deliveryDate: "",
      //     orderProcess: "HK",
      //     proList: [],
      //     payMode: [],
      //     bankBillTerm: "",
      //     invoiceType: "",

      //     //付款分期表格
      //     payDeliverRateFkList: [],
      //     //交货分期表格
      //     payDeliverRateJhList: [],
      //     arbAdditionInfo: {
      //       fqTermIndex: "",
      //       type: "BI", //竞卖
      //       taxRate: "", // 使用税率
      //       invsStatus: "KQ", // 开票时间
      //       paySet: "", //付款安排
      //       deliverSet: "", //交付安排
      //       deliverMode: "", // 交货方式
      //       packageMode: "", //包装方式
      //       checkMode: "", //验收条件
      //       qualityMode: "", //质保方式
      //       qualityTerm: "", //质保的日期
      //       paySetPort: "", // 付款安排数据
      //       deliverSetPort: "", // 交付安排数据

      //       invsDay: "", //付款后开发票日期
      //       deliverModeDay: "", //买家自提的日期
      //       bankBillTerm: "", //承兑汇票期限
      //       payMode: "",
      //       //------------------------
      //       paySetDay: "", //付款安排一次性付款日期

      //       //---------------------------
      //       deliverSetDay: "", //交货安排一次性交货日期

      //       //-----------------------------
      //       payPercent: "", // 付款占比
      //       yhzzPercentage: "", //银行转账百分比
      //       cdhpPercentage: "", //承兑汇票百分比
      //     },
      //   },

      userAddressList: [],
      categoryList: [],
      noRules: {},
      publishFormRules: {
        bidName: [
          { required: true, message: "请输入标的名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        taxRate: [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            pattern: regRule.regPhoneStrict,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
        ],
        // bidPrice: [
        //   {
        //     required: true,
        //     validator: validatePrice,
        //     msg: "请输入标的价格",
        //     trigger: "blur",
        //   },
        // ],
        dateTime: [
          {
            required: true,
            validator: this.validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        deliveryDate: [
          {
            required: true,
            message: "请选择交货时间",
            trigger: ["blur", "change"],
          },
        ],
        deliveryAddress: [
          {
            required: true,
            message: "请输入收货信息",
            trigger: ["blur", "change"],
          },
        ],
        contractInfo: [
          {
            required: true,
            message: "请选择合同模板",
            trigger: ["blur", "change"],
          },
        ],
        orderProcess: [
          {
            required: true,
            message: "请选择订单模式",
            trigger: ["blur", "change"],
          },
        ],
        payMode: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "blur" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        // "arbAdditionInfo.acceptanceQualifiedDate": [
        //   {
        //     required: true,
        //     validator: validatePayAfterData,
        //     trigger: ["blur", "change"],
        //   },
        // ],
        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validatePayAfterData,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,
            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["blur", "change"],
          },
        ],
      },
      productManage: {
        form: {
          productName: "",
          productBrand: "",
          productType: "",
          specification: "",
          number: "",
          unit: "",
          unitPrice: "",
          totalPrice: "",
          require: "",
        },
        formRule: {
          productName: [
            { required: true, validator: validateProductName, trigger: "blur" },
          ],
          productBrand: [
            {
              required: true,
              validator: validateProductBrand,
              trigger: "blur",
            },
          ],
          productType: [
            { required: true, validator: validateProductType, trigger: "blur" },
          ],
          specification: [
            {
              required: true,
              validator: validateSpecification,
              trigger: "blur",
            },
          ],
          number: [
            { required: true, validator: validateNumber, trigger: "blur" },
          ],
          unit: [{ required: true, message: "请选择单位", trigger: "blur" }],
          unitPrice: [
            {
              required: true,
              validator: validateUnitPrice,
              msg: "请输入单价",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        editIndex: "",
        dialogVisible: false,
      },
      contractList: [],
      contractDialogVisible: false,
      contractTemplate: "",
      tenderDetail: null,
      importExcel,
      paymentmethod: {
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        supplement: [],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
    };
  },
  computed: {
    bidTotalPrice() {
      let price = 0;

      if (this.publishForm.proList !== undefined) {
        this.publishForm.proList.forEach((item) => {
          price += item.totalPrice;
        });
      }
      return price;
    },

    RulesShow: function () {
      if (this.examineShow == true) {
        return this.noRules;
      } else {
        return this.publishFormRules;
      }
    },
  },
  created() {
    this.$store.commit("changeMenuType", "BUY");
    this.getBIContract();
    this.getAllCategory();
    if (this.$route.query.isEdit === "true") {
      //   this.getSelectBiddingDetails();
    }
  },
  methods: {
    keyup(e, val) {
      //限制10位整数 与两位小数的正则规则
      if (e.target.value[0] === ".") {
        // e.target.value = e.target.value.replace(/^(\.)/, "0$1");
        e.target.value = e.target.value.replace(/^\./g, "");
        this.productManage.form[val] = e.target.value;
      } else if (e.target.value[0] === "0" && /\d/.test(e.target.value[1])) {
        e.target.value = e.target.value.substring(1);
      } else if (e.target.value.includes(".")) {
        const arr = e.target.value.split(".");
        if (arr[0].length > 10) {
          e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`;
          this.productManage.form[val] = e.target.value;
        } else {
          e.target.value = e.target.value.replace(/[^\d\.]/g, "");
          e.target.value = e.target.value.replace(
            /^(\d{0,10}\.?\d{0,2}).*/,
            "$1"
          );
          // e.target.value = e.target.value.replace(/^\./g, "");
          this.productManage.form[val] = e.target.value;
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        e.target.value = e.target.value.replace(/^(\d{0,10}).*/, "$1");
        this.productManage.form[val] = e.target.value;
      }
    },
    blurmethod(e, val) {
      if (e.target.value[e.target.value.length - 1] === ".") {
        e.target.value = e.target.value.substring(0, [
          e.target.value.length - 1,
        ]);
        this.productManage.form[val] = e.target.value;
      }

      //  console.log( e.target.value.length==12 &&e.target.value.indexOf('.')==-1);
      //  console.log(e.target.value.indexOf('.')==-1);
    },
    resetData() {
      this.deliverSetList = false;
      this.paySetList = false;
      this.publishForm = {
        bidName: "",
        categoryId: "",
        bidPrice: 0,
        startTime: "",
        endTime: "",
        dateTime: "",
        contacts: "",
        phone: "",
        contractInfo: "",
        deliveryAddress: "",
        bidRequire: "",
        delRequire: "",
        pricRequire: "",
        qualRequire: "",
        taxRate: "",
        deliveryDate: "",
        orderProcess: "HK",
        proList: [],
        payMode: [],
        bankBillTerm: "",
        invoiceType: "",

        //付款分期表格
        payDeliverRateFkList: [],
        //交货分期表格
        payDeliverRateJhList: [],
        arbAdditionInfo: {
          fqTermIndex: "",
          type: "BI", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "KQ", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
        },
      };
      this.userAddressList = [];
      this.productManage = {
        form: {
          productName: "",
          productBrand: "",
          productType: "",
          specification: "",
          number: "",
          unit: "",
          unitPrice: "",
          totalPrice: "",
          require: "",
        },
        formRule: {
          productName: [
            { required: true, message: "请输入产品名称", trigger: "blur" },
          ],
          productBrand: [
            { required: true, message: "请输入产品品牌", trigger: "blur" },
          ],
          productType: [
            { required: true, message: "请输入产品型号", trigger: "blur" },
          ],
          specification: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          number: [
            { required: true, validator: validateNumber, trigger: "blur" },
          ],
          unit: [{ required: true, message: "请选择单位", trigger: "blur" }],
          unitPrice: [
            {
              required: true,
              validator: validateUnitPrice,
              msg: "请输入单价",
              trigger: "blur",
            },
          ],
        },
        isEdit: false,
        editIndex: "",
        dialogVisible: false,
      };
      this.contractList = [];
      this.contractDialogVisible = false;
      this.contractTemplate = "";
      this.tenderDetail = null;
      this.paymentmethod.orderProcess = "";
      this.paymentmethod.payMode = "";
      this.paymentmethod.bankBillTerm = "";
    },
    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.publishForm.orderProcess == "KH") {
        this.publishForm.arbAdditionInfo.deliverSetPort = "SK";
      }
    },
    paySetMethod(newVal) {
      console.log("paySetMethod", newVal);
      if (newVal == "QK" && this.publishForm.orderProcess == "HK") {
        this.publishForm.arbAdditionInfo.paySetPort = "YS";
      }
    },
    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      this.publishForm.payDeliverRateFkList = [];
      this.publishForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        let obj = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          payMode: "",

          type: "FK",
        };

        this.publishForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };

        this.publishForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.publishForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.publishForm.payDeliverRateFkList[val].termIndex = value;
    },
    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },

    addPayList() {
      if (parseInt(this.order) < 10) {
        this.order = this.order + 1;
        for (var i = 0; i < 1; i++) {
          var obj = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",

            type: "FK",
          };
          this.publishForm.payDeliverRateFkList.push(obj);

          var obj1 = {
            termIndex: "",
            rate: "",
            date: "",
            payMode: "",

            type: "JH",
          };
          this.publishForm.payDeliverRateJhList.push(obj1);
        }
      }
    },

    getTime() {
      let date = new Date();

      let h = date.getHours(),
        m = date.getMonth(),
        s = date.getSeconds();

      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
        s < 10 ? "0" + s : s
      }`;
    },
    last() {
      if (this.examineShow == true) {
        this.$emit("lastShow");
      } else {
        this.$emit(
          "last",
          JSON.parse(JSON.stringify(this.publishForm)),
          this.id
        );
      }
    },

    next() {
      let isEdit = this.$route.query.isEdit === "true";
      if (this.examineShow == true) {
        this.$emit("nextShow");
      } else {
        this.$refs.publishForm.validate((valid) => {
          if (valid) {
            //   if (
            //     formatNewDate(this.publishForm.startTime).getTime() <=
            //     new Date().getTime()
            //   ) {
            //     return this.$message.warning(
            //       "有效期开始时间的设置需要大于当前时间！"
            //     );
            //   }
            // if (formatNewDate(this.publishForm.endTime).getTime() >= formatNewDate(this.publishForm.deliveryDate).getTime()) {
            //   return this.$message.warning('交货时间的设置需要大于有效期的结束时间！');
            // }
            console.log("this.publishForm.proList", this.publishForm);
            if (!this.publishForm.proList.length)
              return this.$message.info("请添加产品！");

            if (this.bidTotalPrice > 10000000000)
              return this.$message.info("标的价格不超过9999999999.99元");

            // this.$confirm(
            //   `此操作将${isEdit ? "发布" : "修改"}竞标信息, 是否继续?`,
            //   `立即${isEdit ? "发布" : "修改"}`,
            //   {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消",
            //     type: "warning",
            //   }
            // ).then(() => {
            this.publishForm.bidPrice = this.bidTotalPrice;
            this.$emit("next", JSON.parse(JSON.stringify(this.publishForm)));

            this.updateCompetitive();
            // if (isEdit) {
            //   this.updateCompetitive();
            // } else {
            //   this.addCompetitive();
            // }
            // });
          }
        });
      }
    },
    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },

    addProduct() {
      this.$refs.productForm.validate((valid) => {
        if (valid) {
          if (!this.productManage.form.unit) {
            return this.$message.warning("请选择单位");
          }
          if (
            this.productManage.form.number * this.productManage.form.unitPrice >
            10000000000
          ) {
            return this.$message.warning("总价不超过 9999999999.99 元");
          }

          this.productManage.form.totalPrice =
            this.productManage.form.number * this.productManage.form.unitPrice;
          // -----------

          // -----------

          if (!this.productManage.isEdit) {
            this.publishForm.proList.push({ ...this.productManage.form });
          } else {
            this.publishForm.proList.splice(this.productManage.editIndex, 1, {
              ...this.productManage.form,
            });
          }

          this.productManage.dialogVisible = false;
        }
      });
    },
    deleteProduct(index) {
      this.publishForm.proList.splice(index, 1);
    },
    addCompetitive() {
      this.publishForm.payMode = this.publishForm.payMode
        .toString()
        .replaceAll(",", "|");
      // console.log('  this.publishForm.payMode ' , this.publishForm.payMode );

      this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
      console.log(
        "this.publishForm.arbAdditionInfo.payMode",
        this.publishForm.arbAdditionInfo.payMode
      );
      console.log(" this.publishForm.payMode", this.publishForm.payMode);

      if (this.publishForm.payMode.length !== 4) {
        this.publishForm.arbAdditionInfo.payPercent =
          this.publishForm.arbAdditionInfo.yhzzPercentage +
          "|" +
          this.publishForm.arbAdditionInfo.cdhpPercentage;
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHZZ") !== -1
      ) {
        this.publishForm.arbAdditionInfo.payPercent =
          this.publishForm.arbAdditionInfo.yhzzPercentage;
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.publishForm.arbAdditionInfo.payPercent =
          this.publishForm.arbAdditionInfo.cdhpPercentage;
      }

      this.publishForm.arbAdditionInfo.bankBillTerm =
        this.publishForm.bankBillTerm;
      let publishForm = { ...this.publishForm };
      // publishForm.delRequire = this.addPrompt();
      publishForm.categoryId =
        publishForm.categoryId[publishForm.categoryId.length - 1];

      addCompetitive({
        ...publishForm,
      }).then((res) => {
        if (res.errno === 0) {
          this.$message({
            type: "success",
            message: "发布成功！",
          });
          //   this.$router.push({
          //     path: "/User/MyCompetitive",
          //   });
        }
      });
    },
    updateCompetitive() {
      this.publishForm.payMode = this.publishForm.payMode
        .toString()
        .replaceAll(",", "|");
      // console.log('  this.publishForm.payMode ' , this.publishForm.payMode );

      this.publishForm.arbAdditionInfo.payMode = this.publishForm.payMode;
      //百分比

      let YHZZper = this.publishForm.arbAdditionInfo.yhzzPercentage;
      let CDHPper = this.publishForm.arbAdditionInfo.cdhpPercentage;
      let YHHPper = this.publishForm.arbAdditionInfo.yhhpPercentage;

      if (this.publishForm.payMode == [] || this.publishForm.payMode == "") {
        this.publishForm.arbAdditionInfo.payPercent = "";
      } else if (
        this.publishForm.payMode.length == 14 ||
        this.publishForm.payMode.length == 9
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        if (CDHPper == "") {
          CDHPper = "0";
        }
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + CDHPper + "|" + YHHPper;
        console.log("payPercent1");
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHZZ") !== -1
      ) {
        if (YHZZper == "") {
          YHZZper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          YHZZper + "|" + "0" + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("CDHP") !== -1
      ) {
        if (CDHPper == "") {
          CDHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + CDHPper + "|" + "0";
      } else if (
        this.publishForm.payMode.length == 4 &&
        this.publishForm.payMode.indexOf("YHHP") !== -1
      ) {
        if (YHHPper == "") {
          YHHPper = "0";
        }
        this.publishForm.arbAdditionInfo.payPercent =
          "0" + "|" + "0" + "|" + YHHPper;
      }
      this.publishForm.arbAdditionInfo.bankBillTerm =
        this.publishForm.bankBillTerm;

      let publishForm = { ...this.publishForm };

      // publishForm.categoryId =
      //   publishForm.categoryId[publishForm.categoryId.length - 1];
      updateCompetitive({
        // id: this.$route.query.id,
        id: this.id,
        steps: "1",
        ...publishForm,
        categoryId: Array.isArray(this.publishForm.categoryId)
          ? this.publishForm.categoryId[this.publishForm.categoryId.length - 1]
          : this.publishForm.categoryId,
      }).then((res) => {
        if (res.errno === 0) {
          // this.$message({
          //   type: "success",
          //   message: "修改成功！",
          // });
          this.$emit("addNext");
        }
      });
    },
    getBIContract() {
      getBIContract({
        page: 1,
        limit: 100,
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
    onValidityDateTimeChange(value) {
      this.publishForm.startTime = value[0];
      this.publishForm.endTime = value[1];
    },
    onBlurTaxRate(value) {
      if (isNaN(value)) return;
      this.publishForm.taxRate = Number(value).toFixed(2);
    },
    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.list[0].contractTextModel;
        }
      });
      this.contractDialogVisible = true;
    },
    showProductDialog(index, row) {
      this.productManage.form.unit = "";
      if (row) {
        this.productManage.isEdit = true;
        this.productManage.editIndex = index;
        Object.keys(row).forEach(
          (key) => (this.productManage.form[key] = row[key])
        );
      } else {
        this.productManage.isEdit = false;
      }

      this.productManage.dialogVisible = true;
    },
    validateStartTime(rule, value, callback) {
      if (!value) {
        return callback(new Error("请选择有效期"));
      } else if (formatNewDate(value[0]).getTime() < Date.now()) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else if (
        formatNewDate(value[1]).getTime() - formatNewDate(value[0]).getTime() >
        1000 * 60 * 60 * 24 * 30
      ) {
        return callback(new Error("有效期时间不能大于 30 天"));
      } else {
        callback();
      }
    },
    //  表格上传
    beforeAvatarUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      //
      if (!isXlsx) {
        this.$message.error("导入表格文件只能是 .xlsx格式!");
      }
      return isXlsx;
    },
    handleAvatarSuccess(res, file) {
      if (res.errno === 0) {
        this.publishForm.proList = res.data;
        // this.publishForm.proList.map(item=>{
        //   item.totalPrice
        // })
      } else {
        this.$message.error(res.errmsg);
      }
    },
    // 根据选择添加付款方式和提示进合同
    // addPrompt() {
    //   let prompt = this.publishForm;
    //   if (prompt.orderProcess == 'HK') {
    //     if (prompt.payMode == 'YHZZ') {
    //       return `<p>付款方式：银行转账</p>` + prompt.delRequire;
    //     } else {
    //       return `<p>付款方式：商业承兑汇票，承兑期限为${prompt.bankBillTerm}个月</p>` + prompt.delRequire;
    //     }
    //   } else {
    //     if (prompt.payMode == 'YHZZ') {
    //       return `<p>付款方式：银行转账，签订合同后3天以内以银行转账的方式一次性付清</p>` + prompt.delRequire;
    //     } else {
    //       return `<p>付款方式：商业承兑汇票，在签订合同后3天以内以承兑期限为${prompt.bankBillTerm}个月的商业承兑汇票一次性付清</p>` + prompt.delRequire;
    //     }
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row current-row {
  height: 10px;
}

/deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  text-align: center;
}
.addPayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin: 0 0 30px 360px;
}
.reducePayList {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
}
.publish-container {
  background: #fff;
  padding-bottom: 20px;
  .form-content {
    padding-top: 20px;
    margin: 0 auto;
    width: 90%;
    margin-bottom: 30px;
    position: relative;
    .btnBoxStyle {
      .btnLast {
        position: absolute;
        right: 52%;
        z-index: 15;
      }
      .btnNext {
        position: absolute;
        right: 45%;
        z-index: 15;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    // el-date-picker el-popper has-time
    .input-width {
      width: 300px;
      font-size: 12px;
    }
    .input-width-small {
      width: 60px;
    }
    .table-input-width {
      width: 250px;
      margin-top: 20px;
    }
    .capital {
      margin-top: 15px;
    }
    .form-item {
      margin-right: 0;
      margin-bottom: 30px;
      width: 50%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .avatar {
      width: 148px;
      height: 148px;
      display: block;
    }
    .select-style {
      width: 300px;
      .el-select-dropdown__item {
        height: auto;
      }
    }
  }
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      top: -8px;
      left: 0;
    }
    .num {
      bottom: -8px;
      left: 0;
    }
  }
}
.product-content {
  margin-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .upbtn {
      display: flex;
      margin-bottom: 15px;
      .upload-demo {
        margin: 0px 8px;
      }
    }
  }
}
.product-list {
  .input-width {
    width: 280px;
  }
}
/deep/.el-form-item__label {
  font-size: 12px;
  padding: 0;
}

/deep/.mce-tinymce {
  box-shadow: none;
}
</style>
