import { render, staticRenderFns } from "./addProduct.vue?vue&type=template&id=1b67fd0e&scoped=true&"
import script from "./addProduct.vue?vue&type=script&lang=js&"
export * from "./addProduct.vue?vue&type=script&lang=js&"
import style0 from "./addProduct.vue?vue&type=style&index=0&id=1b67fd0e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b67fd0e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xian\\work\\datacockpit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b67fd0e')) {
      api.createRecord('1b67fd0e', component.options)
    } else {
      api.reload('1b67fd0e', component.options)
    }
    module.hot.accept("./addProduct.vue?vue&type=template&id=1b67fd0e&scoped=true&", function () {
      api.rerender('1b67fd0e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/User/components/competitive/PublishCompetitive/components/addProduct.vue"
export default component.exports