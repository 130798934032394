var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "publish-container" },
    [
      _c(
        "div",
        { staticClass: "form-content" },
        [
          _vm.examineShow
            ? _c("div", { staticClass: "transparentBox" })
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "publishForm",
              attrs: {
                inline: "",
                model: _vm.publishForm,
                rules: _vm.publishFormRules,
                "label-width": "130px",
              },
            },
            [
              _c("div", { staticClass: "basicBox" }, [
                _c("div", { staticClass: "titleFont" }, [_vm._v("基本信息")]),
                _c(
                  "div",
                  { staticClass: "basic" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "订单模式：", prop: "orderProcess" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select-style",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value: _vm.publishForm.orderProcess,
                              callback: function ($$v) {
                                _vm.$set(_vm.publishForm, "orderProcess", $$v)
                              },
                              expression: "publishForm.orderProcess",
                            },
                          },
                          _vm._l(
                            _vm.paymentmethod.orderProcess,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "标的名称：", prop: "bidName" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            maxlength: "50",
                            placeholder: "请输入标的名称",
                            size: "small",
                          },
                          model: {
                            value: _vm.publishForm.bidName,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "bidName", $$v)
                            },
                            expression: "publishForm.bidName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "产品类型：", prop: "categoryId" },
                      },
                      [
                        _c("el-cascader", {
                          staticClass: "input-width",
                          attrs: { options: _vm.categoryList, size: "small" },
                          model: {
                            value: _vm.publishForm.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "categoryId", $$v)
                            },
                            expression: "publishForm.categoryId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "标的有效期：", prop: "dateTime" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "input-width",
                          attrs: {
                            size: "small",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            format: "yyyy-MM-dd HH:mm",
                            "picker-options": _vm.pickerStartOptions,
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": this.nowTime,
                          },
                          on: { change: _vm.onValidityDateTimeChange },
                          model: {
                            value: _vm.publishForm.dateTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "dateTime", $$v)
                            },
                            expression: "publishForm.dateTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "合同模板：",
                              prop: "contractInfo",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "input-width",
                                attrs: {
                                  placeholder: "请选择合同模板",
                                  "popper-append-to-body": false,
                                },
                                model: {
                                  value: _vm.publishForm.contractInfo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.publishForm,
                                      "contractInfo",
                                      $$v
                                    )
                                  },
                                  expression: "publishForm.contractInfo",
                                },
                              },
                              _vm._l(_vm.contractList, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.tContractTextNo,
                                    attrs: {
                                      id: "contract-id",
                                      label: item.tContractTextName,
                                      value: item.tContractTextNo,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "contract-box" }, [
                                      _c(
                                        "div",
                                        { staticClass: "contract-left" },
                                        [
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(item.tContractTextName)
                                            ),
                                          ]),
                                          _c("span", { staticClass: "num" }, [
                                            _vm._v(
                                              _vm._s(item.tContractTextNo)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "contract-rigth",
                                          staticStyle: { color: "#8492a6" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showContractDialog(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看 ")]
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "合同编号：",
                              prop: "contractNumber",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input-width",
                              attrs: {
                                maxlength: "50",
                                placeholder: "请输入合同编号",
                                size: "small",
                                onkeyup:
                                  "this.value=this.value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                              },
                              nativeOn: {
                                input: function ($event) {
                                  return _vm.valueNumber(
                                    $event,
                                    "contractNumber"
                                  )
                                },
                              },
                              model: {
                                value: _vm.publishForm.contractNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.publishForm,
                                    "contractNumber",
                                    $$v
                                  )
                                },
                                expression: "publishForm.contractNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "basicBox" }, [
                _c("div", { staticClass: "titleFont" }, [_vm._v("联系信息")]),
                _c(
                  "div",
                  { staticClass: "basic" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "联系人：", prop: "contacts" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "请输入联系人",
                            size: "small",
                            maxlength: "30",
                          },
                          model: {
                            value: _vm.publishForm.contacts,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "contacts", $$v)
                            },
                            expression: "publishForm.contacts",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "手机号码：", prop: "phone" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "请输入联系电话",
                            maxlength: "11",
                            size: "small",
                            onkeyup: "value=value.replace(/[^\\d]/g,'')",
                          },
                          model: {
                            value: _vm.publishForm.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.publishForm, "phone", $$v)
                            },
                            expression: "publishForm.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "收货地址：",
                              prop: "deliveryAddress",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input-width",
                              attrs: {
                                placeholder: "请输入收货地址",
                                size: "small",
                                maxlength: "100",
                              },
                              model: {
                                value: _vm.publishForm.deliveryAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.publishForm,
                                    "deliveryAddress",
                                    $$v
                                  )
                                },
                                expression: "publishForm.deliveryAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "basicBox" }, [
                _c("div", { staticClass: "titleFont" }, [_vm._v("发票信息")]),
                _c(
                  "div",
                  { staticClass: "basic" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: { label: "发票类型：", prop: "invoiceType" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.publishForm.invoiceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.publishForm,
                                      "invoiceType",
                                      $$v
                                    )
                                  },
                                  expression: "publishForm.invoiceType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "0" } }, [
                                  _vm._v("专用发票"),
                                ]),
                                _c(
                                  "el-radio",
                                  { attrs: { disabled: "", label: "1" } },
                                  [_vm._v("普通发票")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "适用税率(%)：",
                              prop: "arbAdditionInfo.taxRate",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input-width",
                              attrs: {
                                placeholder: "请输入税率",
                                size: "mini",
                              },
                              nativeOn: {
                                input: function ($event) {
                                  return _vm.valueChange($event, "taxRate")
                                },
                              },
                              model: {
                                value: _vm.publishForm.arbAdditionInfo.taxRate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.publishForm.arbAdditionInfo,
                                    "taxRate",
                                    $$v
                                  )
                                },
                                expression:
                                  "publishForm.arbAdditionInfo.taxRate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "开票时间：",
                              prop: "arbAdditionInfo.invsStatus",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.publishForm.arbAdditionInfo.invsStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.publishForm.arbAdditionInfo,
                                      "invsStatus",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "publishForm.arbAdditionInfo.invsStatus",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "KQ" } }, [
                                  _vm._v("付款前开发票"),
                                ]),
                                _c("el-radio", { attrs: { label: "KH" } }, [
                                  _vm._v("付款后开发票"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-invsStatus",
                            attrs: { prop: "arbAdditionInfo.invsDay" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { "font-size": "14px" },
                                style: {
                                  color:
                                    _vm.publishForm.arbAdditionInfo
                                      .invsStatus == "KH"
                                      ? "#000"
                                      : "#c0c4cc",
                                },
                              },
                              [
                                _vm._v(" 付款之日起【"),
                                _c("el-input", {
                                  staticClass: "input-width-small",
                                  attrs: {
                                    disabled:
                                      this.publishForm.arbAdditionInfo
                                        .invsStatus == "KH"
                                        ? false
                                        : true,
                                    size: "mini",
                                  },
                                  model: {
                                    value:
                                      _vm.publishForm.arbAdditionInfo.invsDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.publishForm.arbAdditionInfo,
                                        "invsDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "publishForm.arbAdditionInfo.invsDay",
                                  },
                                }),
                                _vm._v("】个工作日内开票 "),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "btnBoxStyle" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnstyle",
                      attrs: { type: "primary" },
                      on: { click: _vm.next },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "合同模板",
            visible: _vm.contractDialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.contractDialogVisible = $event
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.contractTemplate) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }